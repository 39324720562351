import React, { useState, useEffect, useCallback } from "react";
import adminLayout from "../hoc/adminLayout.js";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import debounce from "lodash/debounce";
import baseURL from "../common/base-urls.js";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";
const UserEntry = () => {
    const navigate = useNavigate();
    const pageData = useLocation();
    const [apiUrl, setApiUrl] = useState('');
    useEffect(() => {
        const currentTime = new Date();
        const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        //console.log(`=======================================`);
        //console.log(`========= Updated on ${formattedTime} =========`);
        //console.log(`=======================================`);
        //console.log(pageData);
        return () => {
        };
    }, [pageData]);
    //#region ====================================================== Create State =====================================================
    const [state, setState] = useState({
        show_toast: false,
        toast_msg: "No record found",
        show_modal: false,
        saved: false,
        is_edit: false,
        data_assigned: false,
        modal_title: "Confirmation",
        modal_msg:
            "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
        modal_button: "Delete",
        chkall: true,
        write_status: true,
        view_status: true,
        update_status: true,
        delete_status: true,
        fields: {
            role_id: "1",
            designation_id: "0",
            institute_id: "1",
            title: "", intro: "",
            user_code: "",
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            is_active: true,
            is_block: false,
            is_codeblue: true,
            is_all_display: true,
            is_summary_send: true,
            is_login: true,
            pagelist: [],
        },
        apifields: {
            role_id: "1",
            designation_id: "0",
            institute_id: "1",
            title: "", intro: "",
            user_code: "",
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            is_active: true,
            is_block: false,
            is_codeblue: true,
            is_all_display: true,
            is_summary_send: true,
            is_login: true,
            pagelist: [],
        },
        oldfields: {
            role_id: "1",
            designation_id: "0",
            institute_id: "1",
            title: "", intro: "",
            user_code: "",
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            is_active: true,
            is_block: false,
            is_codeblue: true,
            is_all_display: true,
            is_summary_send: true,
            is_login: true,
            pagelist: [],
        },
    });
    //#endregion
    //#region ====================================================== Shortcuts =====================================================
    document.addEventListener('keydown', function (event) {
        // Check if the key combination is Ctrl + S
        if (event.ctrlKey && event.key === 's') {
            // Prevent the default browser save behavior
            event.preventDefault();
            // Trigger the click event on your save button
            var saveButton = document.getElementById('btnsave');
            if (saveButton) {
                saveButton.click();
            }
        }
    });
    //#endregion
    //#region ====================================================== Update State ======================================================
    const updateState = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    //#endregion
    //#region ====================================================== Page Rights ======================================================
    const rightsURL = `user/chkuserrights?form_id=${11}`;
    const {
        data: tblhasrights,
        error: error1,
        isValidating: isValidating2,
    } = useSWR(rightsURL, () => getAPI(rightsURL, true), {
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
    });
    //#endregion
    //#region ====================================================== Designation Dropdown Bind ======================================================
    let tbldesignations = {};
    let apiDesignation = `designation?page=1&limit=50`;
    const { data: designationdata, errordesignation, isValidatingdesignation } = useSWR(
        apiDesignation,
        () => getAPI(apiDesignation, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tbldesignations = designationdata;
    //#endregion


    //#endregion
    //#region ====================================================== Create or Edit Record ======================================================
    let tbldata = {};
    useEffect(() => {
        if (
            tblhasrights &&
            tblhasrights.data &&
            Array.isArray(tblhasrights.data.hasrights) &&
            tblhasrights.data.hasrights.length > 0 &&
            tblhasrights.data.hasrights[0].hasOwnProperty('update_status') &&
            tblhasrights.data.hasrights[0]['update_status']
        ) {
            if (pageData.state && state.is_edit == false && state.data_assigned == false) {
                //console.log("inside get edit")
                const getData = async () => {
                    try {
                        const result = await getAPI(`user/${pageData.state.edit_id}`, false);
                        if (result && result.statusCode === 200 && result.success === true) {
                            tbldata = result;
                            ////console.log(tbldata)
                            setState((prevState) => {
                                const updatedPagelist = prevState.fields.pagelist.map(item => {
                                    const matchingUserRole = tbldata.data.user_roles.find(role => role.form_id === item.form_id);
                                    if (matchingUserRole) {
                                        // If form_id matches, update the relevant properties
                                        const chkall = matchingUserRole.write_status && matchingUserRole.view_status &&
                                            matchingUserRole.update_status && matchingUserRole.delete_status;
                                        return {
                                            ...item,
                                            chkall: chkall,
                                            write_status: matchingUserRole.write_status,
                                            view_status: matchingUserRole.view_status,
                                            update_status: matchingUserRole.update_status,
                                            delete_status: matchingUserRole.delete_status,
                                        };
                                    }
                                    else {
                                        return {
                                            ...item,
                                            chkall: false,
                                            write_status: false,
                                            view_status: false,
                                            update_status: false,
                                            delete_status: false,
                                        };
                                    }

                                    // If form_id doesn't match, return the original item
                                    return item;
                                });
                                const allChkallTrue = updatedPagelist.every(item => item.chkall);
                                const allwritestatus = updatedPagelist.every(item => item.write_status);
                                const allupdatestatus = updatedPagelist.every(item => item.update_status);
                                const allviewstatus = updatedPagelist.every(item => item.view_status);
                                const alldeletestatus = updatedPagelist.every(item => item.delete_status);
                                return {
                                    ...prevState,
                                    chkall: allChkallTrue,
                                    write_status: allwritestatus,
                                    update_status: allupdatestatus,
                                    view_status: allviewstatus,
                                    delete_status: alldeletestatus,
                                    fields: {
                                        ...prevState.fields,
                                        role_id: tbldata.data.role_id,
                                        designation_id: tbldata.data.designation_id,
                                        institute_id: tbldata.data.institute_id,
                                        title: tbldata.data.title,
                                        intro: tbldata.data.intro,
                                        user_code: tbldata.data.user_code,
                                        first_name: tbldata.data.first_name,
                                        last_name: tbldata.data.last_name,
                                        email: tbldata.data.email,
                                        phone: tbldata.data.phone,
                                        is_active: tbldata.data.is_active,
                                        is_block: tbldata.data.is_block,
                                        is_codeblue: tbldata.data.is_codeblue,
                                        is_all_display: tbldata.data.is_all_display,
                                        is_summary_send: tbldata.data.is_summary_send,
                                        is_login: tbldata.data.is_login,
                                        pagelist: updatedPagelist,
                                    },
                                };
                            });

                            updateState("data_assigned", true);
                        } else {
                            showToast("Something went wrong. Please try again later.");
                        }
                    } catch (err) {
                    }
                };
                getData();
                updateState("is_edit", true);
            }
        }
    }, [tblhasrights, pageData]);
    //#endregion 
    //#region ====================================================== PageList Bind ======================================================
    let tblpagelist = {};
    let apipagelist = `report/pagelist`;
    const { data: pagelistdata, errorpagelist, isValidatingpagelist } = useSWR(
        apipagelist,
        () => getAPI(apipagelist, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tblpagelist = pagelistdata;
    //#endregion
    useEffect(() => {
        // Update tblpagelist in apifields state when pagelistdata changes
        if (pagelistdata) {
            const updatedPagelist = pagelistdata.data.pagelist.map((item) => ({
                form_id: item.form_id,
                chkall: true, // You can set the initial value as needed
                write_status: true, // You can set the initial value as needed
                update_status: true,
                delete_status: true,
                view_status: true,
            }));
            let role_id = 2;
            let institute_id = null;
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                role_id = getUser.role_id;
                if (role_id == 2) {
                    institute_id = getUser.institute_id;
                }
            }
            setState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    role_id: role_id,
                    institute_id: institute_id,
                    pagelist: updatedPagelist,
                },
            }));
        }
    }, [pagelistdata]);
    //#region ====================================================== Input Textbox ======================================================
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };

    const handleInputChangePhone = (event) => {
        const { name, value } = event.target;
        // Validate if the entered value is a number and has a length of 10 or less
        if (/^\d{0,10}$/.test(value)) {
            setState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    [name]: value,
                },
            }));
        }
    };

    // ...


    //#endregion
    //#region ====================================================== Checkbox Handler ======================================================
    const handleCheckboxClick = (fieldName) => {
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [fieldName]: !prevState.fields[fieldName],
            },
        }));
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChange = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [event.target.name]: selectedOptions,
            },
        }));
    };
    //#endregion



    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleCheckboxChange = (formId, checkboxName) => (event) => {
        const { checked } = event.target;
        if (checkboxName == "chkall") {

            setState((prevState) => {
                const updatedPagelist = prevState.fields.pagelist.map((item) => {
                    const updatedItem = item.form_id === formId
                        ? { ...item, chkall: checked, write_status: checked, view_status: checked, update_status: checked, delete_status: checked }
                        : item;
                    return updatedItem;
                });
                // Check if all chkall values are true
                const allChkallTrue = updatedPagelist.every(item => item.chkall);
                const allwritestatus = updatedPagelist.every(item => item.write_status);
                const allupdatestatus = updatedPagelist.every(item => item.update_status);
                const allviewstatus = updatedPagelist.every(item => item.view_status);
                const alldeletestatus = updatedPagelist.every(item => item.delete_status);
                return {
                    ...prevState,
                    chkall: allChkallTrue,
                    write_status: allwritestatus,
                    update_status: allupdatestatus,
                    view_status: allviewstatus,
                    delete_status: alldeletestatus,
                    fields: {
                        ...prevState.fields,
                        pagelist: updatedPagelist,
                    },
                };
                // return {

                //     chkall: allChkallTrue,
                //     fields: {
                //         ...prevState.fields,
                //         pagelist: updatedPagelist,
                //     },
                // };
            });
        }
        else {
            setState((prevState) => {
                const updatedPagelist = prevState.fields.pagelist.map((item) => {
                    if (item.form_id === formId) {
                        const updatedItem = {
                            ...item,
                            [checkboxName]: checked
                        };
                        // Check if all other statuses are true to set chkall to true
                        const allStatusesTrue = ['write_status', 'update_status', 'view_status', 'delete_status'].every(status => updatedItem[status]);
                        // Check if all other statuses are false to set chkall to false
                        const allStatusesFalse = ['write_status', 'update_status', 'view_status', 'delete_status'].every(status => !updatedItem[status]);
                        // if (allStatusesTrue) {
                        return {
                            ...updatedItem,
                            chkall: allStatusesTrue,
                        };
                        // }
                        // else {
                        //     return {
                        //         ...updatedItem,
                        //         chkall: allStatusesFalse,
                        //     };
                        // }
                    }
                    return item;
                });
                const allChkallTrue = updatedPagelist.every(item => item.chkall);
                const allwritestatus = updatedPagelist.every(item => item.write_status);
                const allupdatestatus = updatedPagelist.every(item => item.update_status);
                const allviewstatus = updatedPagelist.every(item => item.view_status);
                const alldeletestatus = updatedPagelist.every(item => item.delete_status);
                return {
                    ...prevState,
                    chkall: allChkallTrue,
                    write_status: allwritestatus,
                    update_status: allupdatestatus,
                    view_status: allviewstatus,
                    delete_status: alldeletestatus,
                    fields: {
                        ...prevState.fields,
                        pagelist: updatedPagelist,
                    },
                };
            });
        }
    };



    const handleHeaderCheckboxChange = (formId, type) => {
        if (type === 'chkall') {
            // Handle the "Select All" checkbox
            if (state && state.fields.pagelist) {

                updateState("chkall", !state.chkall);
                updateState("write_status", !state.chkall);
                updateState("view_status", !state.chkall);
                updateState("update_status", !state.chkall);
                updateState("delete_status", !state.chkall);
                const updatedData = state.fields.pagelist.map(item => ({
                    ...item,
                    chkall: !state.chkall,
                    write_status: !state.chkall,
                    view_status: !state.chkall,
                    update_status: !state.chkall,
                    delete_status: !state.chkall,
                }));
                setState((prevState) => ({
                    ...prevState,
                    fields: {
                        ...prevState.fields,
                        pagelist: updatedData,
                    },
                }));

            }
        }
        else if (['write_status', 'view_status', 'update_status', 'delete_status'].includes(type)) {
            // Handle the individual checkbox for write, view, update, or delete status
            if (state && state.fields.pagelist) {
                updateState(type, !state[type]);
                const updatedData = state.fields.pagelist.map(item => ({
                    ...item,
                    [type]: !state[type],
                }));
                setState((prevState) => ({
                    ...prevState,
                    fields: {
                        ...prevState.fields,
                        pagelist: updatedData,
                    },
                }));
            }
        }
    };
    //#endregion

    //#region ====================================================== Date validation ======================================================
    const handleDateChange = (event) => {
        const { name, value } = event.target;
        const { start_date, end_date } = state.fields;
        const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);
        if (!isValidDate) {
            showToast("Invalid date format");
            return;
        }
        if (name === "start_date" && new Date(value) > new Date(end_date)) {
            showToast("Start date cannot be greater than end date");
            return;
        }
        if (name === "end_date" && new Date(value) < new Date(start_date)) {
            showToast("End date cannot be less than start date");
            return;
        }
        // Update the state
        setState((prevState) => ({
            ...prevState,
            fields: { ...prevState.fields, [name]: value },
        }));
    };
    //#endregion
    //#region ====================================================== Clear or Create/Edit Record ======================================================
    const areObjectsEqual = (obj1, obj2) => {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            const val1 = obj1[key];
            const val2 = obj2[key];

            if (typeof val1 === 'object' && typeof val2 === 'object') {
                if (!areObjectsEqual(val1, val2)) {
                    return false;
                }
            } else if (val1 !== val2) {
                return false;
            }
        }

        return true;
    };
    useEffect(() => {
        if (state.saved === true) {
            //console.log(state);
            if (state.is_edit === false) {
                const saveData = async () => {
                    try {
                        if (!areObjectsEqual(state.oldfields, state.apifields)) {
                            //  const newapiFields = state.apifields;
                            const newapiFields = { ...state.apifields };
                            const result = await postAPI('user', newapiFields, false);
                            if (result && result.statusCode === 201 && result.success === true) {
                                showToast("Record has been added successfully.");
                                clearData();
                            } else {
                                showToast("Something went wrong. Please try again later.");
                            }
                        } else {
                            showToast("Data has not changed. Please add unique records.");
                        }
                    } catch (err) {
                    }
                };
                saveData();
                updateState("saved", false);
            }
            else {
                //console.log("inside edit");
                const editData = async () => {
                    try {
                        //console.log("state.apifields");
                        //console.log(state.apifields);
                        const newapiFields = { ...state.apifields };
                        const result = await putAPI('user', newapiFields, false);
                        if (result && result.statusCode === 200 && result.success === true) {
                            showToast("Record has been updated successfully.");
                            clearData();
                            updateState("saved", false);
                            updateState("is_edit", false);
                            navigate("/userlist");
                        } else {
                            showToast("Something went wrong. Please try again later.");
                        }
                    } catch (err) {

                    }
                };
                editData();


            }
        }
    }, [state.saved]);
    const saveClicked = () => {
        //console.log("state.apifields")

        if (
            state.fields.first_name &&
            state.fields.last_name &&
            /^\d{0,10}$/.test(state.fields.phone) && state.fields.phone.length == 10 &&  // Validate phone as integer up to 10 digits
            state.fields.designation_id && state.fields.designation_id != "0" &&
            state.fields.title && state.fields.title != "0" &&
            state.fields.pagelist &&  // Check if pagelist is truthy (not null or undefined)
            state.fields.pagelist.length > 0
        ) {
            setState((prevState) => ({
                ...prevState,
                saved: false
            }));
            setState((prevState) => ({
                ...prevState,
                saved: true,
                apifields: {
                    ...prevState.apifields,
                    role_id: prevState.fields.role_id,
                    designation_id: prevState.fields.designation_id,
                    institute_id: prevState.fields.institute_id,
                    title: prevState.fields.title,
                    intro: prevState.fields.intro,
                    user_code: prevState.fields.user_code,
                    first_name: prevState.fields.first_name,
                    last_name: prevState.fields.last_name,
                    email: prevState.fields.email,
                    phone: prevState.fields.phone,
                    is_active: prevState.fields.is_active,
                    is_block: prevState.fields.is_block,
                    is_codeblue: prevState.fields.is_codeblue,
                    is_all_display: prevState.fields.is_all_display,
                    is_summary_send: prevState.fields.is_summary_send,
                    is_login: prevState.fields.is_login,
                    pagelist: prevState.fields.pagelist,
                },
            }));
        }
        else {
            if (!state.fields.first_name) {
                showToast("First name cannot be empty.");
            }
            else
                if (!state.fields.last_name) {
                    showToast("Last name cannot be empty.");
                }
                else if (!/^\d{0,10}$/.test(state.fields.phone) || state.fields.phone.length !== 10) {
                    showToast("Phone should be number with exactly 10 digits.");
                }

                else if (!state.fields.designation_id || state.fields.designation_id === "0") {
                    showToast("Please select a valid designation.");
                }

                else if (!state.fields.title || state.fields.title === "0") {
                    showToast("Please select a valid title.");
                }

                else if (!state.fields.pagelist || state.fields.pagelist.length === 0) {
                    showToast("You must assign rights.");
                }
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const debouncedSaveClicked = debounce(saveClicked, 500);
    const clearData = async () => {
        try {
            let role_id = 2;
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                role_id = getUser.role_id;
            }
            const updatedapifieldsState = {
                role_id: role_id,
                designation_id: "0",
                institute_id: "1",
                title: "", intro: "",
                user_code: "",
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                is_active: true,
                is_block: false,
                is_codeblue: true,
                is_all_display: true,
                is_summary_send: true,
                is_login: true,
                pagelist: state.fields.pagelist,
            };
            const updatedfieldState = {
                role_id: role_id,
                designation_id: "0",
                institute_id: "1",
                title: "", intro: "",
                user_code: "",
                first_name: "",
                last_name: "",
                email: "",
                phone: "",
                is_active: true,
                is_block: false,
                is_codeblue: true,
                is_all_display: true,
                is_summary_send: true,
                is_login: true,
                pagelist: state.fields.pagelist,
            };
            //console.log(state.apifields)
            updateState("oldfields", state.apifields);
            updateState("apifields", updatedapifieldsState);
            updateState("fields", updatedfieldState);
        } catch (err) { }
    };
    //#endregion
    //#region ====================================================== Back or FAQ ======================================================
    const Back = () => {
        try {

            window.scrollTo({ top: 0, behavior: 'smooth' });
            navigate("/userlist");
        } catch (err) { }
    };
    const debouncedBack = debounce(Back, 500);
    const FAQ = () => {
        try {

            window.scrollTo({ top: 0, behavior: 'smooth' });
            navigate("/FAQ");
        } catch (err) { }
    };
    const debouncedFAQ = debounce(FAQ, 500);
    //#endregion
    //#region ====================================================== Toast Message set ======================================================
    const showToast = (message) => {
        updateState("show_toast", true);
        updateState("toast_msg", message);
        setTimeout(() => {
            updateState("show_toast", false);
        }, 5000);
    };
    const hideToast = () => {
        updateState("show_toast", false);
    };
    //#endregion






    //#region ====================================================== Get API ======================================================
    const getAPI = async (url, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    url += `&user_id=${getUser.user_id}`;
                }
                //console.log("inside GEt")
                //console.log(url)
                const response = await axios.get(`${baseURL.serverUrl}${url}`, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                    },
                });

                //console.log(response.data)
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion
    //#region ====================================================== Post API ======================================================
    const postAPI = async (url, data, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    // Pass user_id through data
                    data.user_id = getUser.user_id;
                }
                //console.log("inside Post data");
                //console.log(data);
                const response = await axios.post(`${baseURL.serverUrl}${url}`, data, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                        'Content-Type': 'application/json',
                    },
                });
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion 
    //#region ====================================================== Post API ======================================================
    const putAPI = async (url, data, Auth) => {
        try {

            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    data.user_id = getUser.user_id;
                }
                //console.log("inside put data");
                //console.log(data);
                const response = await axios.put(`${baseURL.serverUrl}${url}/${pageData.state.edit_id}`, data, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                        'Content-Type': 'application/json',
                    },
                });
                //console.log(response)
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion 
    return (
        <>
            <>
                {/* ====================================================== Head Section ====================================================== */}
                <div className="nk-block-head">
                    <div className="nk-block-head-between flex-wrap gap g-2">
                        {/* ====================================================== Page Name ====================================================== */}
                        <div className="nk-block-head-content">
                            <h2 className="nk-block-title">Users</h2>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="#">Users</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Entry
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        {/* ====================================================== Add New record filter export ====================================================== */}
                        <div className="nk-block-head-content">
                            <ul className="d-flex">
                                {tblhasrights &&
                                    tblhasrights.data &&
                                    Array.isArray(tblhasrights.data.hasrights) &&
                                    tblhasrights.data.hasrights.length > 0 &&
                                    tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                                    tblhasrights.data.hasrights[0]["view_status"] && (
                                        <>
                                            {!state.show_filter && (
                                                <>
                                                    <li>
                                                        <a
                                                            className="btn btn-outline-light btn-md d-md-inline-flex mr10"
                                                            onClick={debouncedFAQ}
                                                        >
                                                            <em className="icon ni ni-info"></em>
                                                            <span>FAQ</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="btn btn-primary d-none d-md-inline-flex"
                                                            onClick={debouncedBack}
                                                        >
                                                            <em className="icon ni ni-arrow-left"></em>
                                                            <span>Back</span>
                                                        </a>
                                                    </li>
                                                </>
                                            )}

                                        </>
                                    )}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* ====================================================== Body section ====================================================== */}
                <div className="nk-block">
                    <ul className="nav nav-tabs mb-3 nav-tabs-s1 pos-sticky">
                        <li className="nav-item">    <a className="nav-link active" href="#summary">Summary</a>  </li>
                        <li className={state.fields.role_id && state.fields.role_id == "2" ? "nav-item" : "nav-item-no"}>    <a className="nav-link" href="#login">{state.fields.role_id && state.fields.role_id == "2" && (
                            <>Login & Notifications  </>
                        )}</a>  </li>

                        <li className="nav-item">    <a className="nav-link" href="#roles">Role & Rights</a>  </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="summary">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="summary">Summary</h5>
                                            <p className="ptitle">Enter essential user details, including designation, title, name, and contact information for admin panel access.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="tax-class" className="form-label">Designation</label>
                                                <div className="form-control-wrap">
                                                    <Select2
                                                        data={[
                                                            { text: "select an option", id: 0 },
                                                            ...(tbldesignations && tbldesignations.data && tbldesignations.data.hasOwnProperty("designations") && tbldesignations.data.designations || []).map((x) => ({
                                                                text: x.designation_name,
                                                                id: x.designation_id,
                                                                key: x.designation_id.toString(),
                                                            })),
                                                        ]}
                                                        options={{
                                                            placeholder: "select an option",
                                                        }}
                                                        name="designation_id"
                                                        className="form-control"
                                                        value={state.fields.designation_id}
                                                        onChange={handleInputChange}
                                                    />

                                                    {/* <select className="js-select select2_single" id="dddesignation" data-search="true" data-sort="false">
                                                        <option value="">Select an option</option>
                                                        <option value="1">Admin</option>
                                                        <option value="2">Sub admin</option>
                                                    </select> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="tax-class" className="form-label">Title</label>
                                                <div className="form-control-wrap">
                                                    <Select2
                                                        data={[
                                                            { text: "select an option", id: 0 },
                                                            { text: "Mr.", id: "Mr." },
                                                            { text: "Ms.", id: "Ms." },
                                                            { text: "Mrs.", id: "Mrs." },
                                                            { text: "Dr.", id: "Dr." },
                                                            { text: "Other", id: "Other" },
                                                        ]}
                                                        options={{
                                                            placeholder: "select an option",
                                                        }}
                                                        name="title"
                                                        className="form-control"
                                                        value={state.fields.title}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">User code</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        placeholder="User code"
                                                        name="user_code"
                                                        className="form-control"
                                                        value={state.fields.user_code}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Firstname</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter firstname"
                                                        name="first_name"
                                                        className="form-control"
                                                        value={state.fields.first_name}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Lastname</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter lastname"
                                                        name="last_name"
                                                        className="form-control"
                                                        value={state.fields.last_name}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Email</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter email"
                                                        name="email"
                                                        className="form-control"
                                                        value={state.fields.email}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Phone</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter phone"
                                                        name="phone"
                                                        className="form-control"
                                                        value={state.fields.phone}
                                                        onChange={handleInputChangePhone}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Active status</label>
                                                <div className="form-control-wrap">
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={state.fields.is_active}
                                                            onChange={() => handleCheckboxClick("is_active")}
                                                            value=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">Block user</label>
                                                <div className="form-control-wrap">
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={state.fields.is_block}
                                                            onChange={() => handleCheckboxClick("is_block")}
                                                            value=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Small intro</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter lastname"
                                                        name="intro"
                                                        className="form-control"
                                                        value={state.fields.intro}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                </div>


                            </div>
                        </div>
                        <div className="tab-pane fade show active" id="login">
                            {state.fields.role_id && state.fields.role_id == "2" && (
                                <>    <span className="divider "></span>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-12">

                                                    <h5 className="summary">Login</h5>
                                                    <p className="ptitle">Toggle the "Login Access" to grant or revoke access for web based admin panel.</p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Login access</label>
                                                        <div className="form-control-wrap">
                                                            <div className="form-check form-switch">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={state.fields.is_login}
                                                                    onChange={() => handleCheckboxClick("is_login")}
                                                                    value=""
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="tab-pane fade show active" id="roles">
                            <span className="divider "></span>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="summary">Roles & Rights Configuration</h5>
                                            <p className="ptitle">Assign roles and rights for each page. Simply select the desired pages, and toggle add, view, modify, and delete options. Only selected pages will be visible to current user. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <table className="datatable-init table" data-nk-container="table-responsive">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="tb-col"><span className="overline-title">Page List</span></th>
                                                        <th className="tb-col">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={state.chkall}
                                                                    onChange={() => handleHeaderCheckboxChange("", "chkall")}
                                                                    value=""
                                                                />

                                                                {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /> */}
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">    <span className="overline-title">
                                                                    Select all</span>
                                                                </label>
                                                            </div>
                                                        </th>
                                                        <th className="tb-col">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={state.write_status}
                                                                    onChange={() => handleHeaderCheckboxChange("", "write_status")}
                                                                    value=""
                                                                />
                                                                {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />  */}
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">    <span className="overline-title">Add</span>  </label></div>
                                                        </th>
                                                        <th className="tb-col">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={state.view_status}
                                                                    onChange={() => handleHeaderCheckboxChange("", "view_status")}
                                                                    value=""
                                                                />
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">    <span className="overline-title">View</span>  </label></div>
                                                        </th>
                                                        <th className="tb-col">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={state.update_status}
                                                                    onChange={() => handleHeaderCheckboxChange("", "update_status")}
                                                                    value=""
                                                                />
                                                                <label className="form-check-label" htmlFor="flexCheckDefault">    <span className="overline-title">Modify</span>  </label></div>
                                                        </th>
                                                        <th className="tb-col">
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={state.delete_status}
                                                                    onChange={() => handleHeaderCheckboxChange("", "delete_status")}
                                                                    value=""
                                                                /> <label className="form-check-label" htmlFor="flexCheckDefault">    <span className="overline-title">Delete</span>  </label></div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {tblpagelist &&
                                                        tblpagelist.data.pagelist.map((x) => (

                                                            <tr key={x?.fomr_id}>
                                                                <td className="tb-col">{x.form_name}</td>
                                                                <td className="tb-col">
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={state.fields.pagelist.find(item => item.form_id === x.form_id)?.chkall || false}
                                                                            onChange={handleCheckboxChange(x.form_id, 'chkall')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexCheckDefault">  </label></div>
                                                                </td>
                                                                <td className="tb-col">
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={state.fields.pagelist.find(item => item.form_id === x.form_id)?.write_status || false}
                                                                            onChange={handleCheckboxChange(x.form_id, 'write_status')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexCheckDefault">  </label></div>
                                                                </td>
                                                                <td className="tb-col">
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={state.fields.pagelist.find(item => item.form_id === x.form_id)?.view_status || false}
                                                                            onChange={handleCheckboxChange(x.form_id, 'view_status')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexCheckDefault">  </label></div>
                                                                </td>
                                                                <td className="tb-col">
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={state.fields.pagelist.find(item => item.form_id === x.form_id)?.update_status || false}
                                                                            onChange={handleCheckboxChange(x.form_id, 'update_status')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexCheckDefault">  </label></div>
                                                                </td>
                                                                <td className="tb-col">
                                                                    <div className="form-check">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={state.fields.pagelist.find(item => item.form_id === x.form_id)?.delete_status || false}

                                                                            onChange={handleCheckboxChange(x.form_id, 'delete_status')}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="flexCheckDefault">  </label></div>
                                                                </td>
                                                            </tr>
                                                        ))}


                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
                {/* ====================================================== Footer Section ====================================================== */}
                < div className="divbottom" >
                    <div className="bottombutton ">
                        <ul className="d-flex align-items-center gap g-3">
                            <li>
                                <a
                                    className="btn  btn-outline-light"
                                    onClick={debouncedBack}
                                >
                                    <em className="icon ni ni-arrow-left"></em>
                                    <span>Cancel</span>
                                </a>
                            </li>
                            {tblhasrights &&
                                tblhasrights.data &&
                                Array.isArray(tblhasrights.data.hasrights) &&
                                tblhasrights.data.hasrights.length > 0 &&
                                tblhasrights.data.hasrights[0].hasOwnProperty("write_status") &&
                                tblhasrights.data.hasrights[0]["write_status"] && (
                                    <>
                                        <li> <button
                                            type="button"
                                            className="btn btn-primary"
                                            name="btnsave"
                                            id="btnsave"
                                            onClick={debouncedSaveClicked}
                                        >
                                            <span>{state.is_edit ? 'Update record' : 'Create record'}</span>
                                        </button> </li>
                                    </>
                                )}


                        </ul>
                    </div>
                </div >
                {/* ====================================================== Toast message ====================================================== */}
                {
                    state.show_toast && (
                        <div
                            className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div className="d-flex">
                                <div className="toast-body">{state.toast_msg}</div>
                                <button
                                    type="button"
                                    className="btn-close btn-close-white me-2 m-auto"
                                    data-bs-dismiss="toast"
                                    onClick={(e) => hideToast()}
                                    aria-label="Close"
                                ></button>
                            </div>
                        </div>
                    )
                }
            </>
        </>
    );
};
export default adminLayout(UserEntry);