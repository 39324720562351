import React, { useState, useEffect, useCallback } from "react";
import adminLayout from "../hoc/adminLayout.js";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import debounce from "lodash/debounce";
import baseURL from "../common/base-urls.js";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";
const CourseSlotBookingEntry = () => {
    const navigate = useNavigate();
    const pageData = useLocation();
    const [apiUrl, setApiUrl] = useState('');
    useEffect(() => {
        const currentTime = new Date();
        const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        //console.log(`=======================================`);
        //console.log(`========= Updated on ${formattedTime} =========`);
        //console.log(`=======================================`);
        //console.log(pageData);
        return () => {
        };
    }, [pageData]);
    //#region ====================================================== Create State =====================================================
    const [state, setState] = useState({
        show_toast: false,
        toast_msg: "No record found",
        show_modal: false,
        saved: false,
        is_edit: false,
        data_assigned: false,
        modal_title: "Confirmation",
        modal_msg:
            "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
        modal_button: "Delete",
        chkall: true,
        write_status: true,
        view_status: true,
        update_status: true,
        delete_status: true,
        fields: {
            course_id: "0",
            institute_id: "1",
            slot_on: "",
            booking_start_on: "",
            booking_end_on: "",
            max_count: "0",
            total_hours: "0",
            is_active: true,
            is_status: false
        },
        apifields: {
            course_id: "0",
            institute_id: "1",
            slot_on: "",
            booking_start_on: "",
            booking_end_on: "",
            max_count: "0",
            total_hours: "0",
            is_active: true,
            is_status: false
        },
        oldfields: {
            course_id: "0",
            institute_id: "1",
            slot_on: "",
            booking_start_on: "",
            booking_end_on: "",
            max_count: "0",
            total_hours: "0",
            is_active: true,
            is_status: false
        },
    });
    //#endregion
    //#region ====================================================== Shortcuts =====================================================
    document.addEventListener('keydown', function (event) {
        // Check if the key combination is Ctrl + S
        if (event.ctrlKey && event.key === 's') {
            // Prevent the default browser save behavior
            event.preventDefault();
            // Trigger the click event on your save button
            var saveButton = document.getElementById('btnsave');
            if (saveButton) {
                saveButton.click();
            }
        }
    });
    //#endregion
    //#region ====================================================== Update State ======================================================
    const updateState = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    //#endregion
    //#region ====================================================== Page Rights ======================================================
    const rightsURL = `user/chkuserrights?form_id=${4}`;
    const {
        data: tblhasrights,
        error: error1,
        isValidating: isValidating2,
    } = useSWR(rightsURL, () => getAPI(rightsURL, true), {
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
    });
    //#endregion
    //#region ====================================================== course Dropdown Bind ======================================================
    let tblcourses = {};
    let apicourse = `course?page=1&limit=500`;
    const { data: coursedata, errorcourse, isValidatingcourse } = useSWR(
        apicourse,
        () => getAPI(apicourse, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tblcourses = coursedata;
    //#endregion  
    //#region ====================================================== Create or Edit Record ======================================================
    let tbldata = {};
    useEffect(() => {
        if (
            tblhasrights &&
            tblhasrights.data &&
            Array.isArray(tblhasrights.data.hasrights) &&
            tblhasrights.data.hasrights.length > 0 &&
            tblhasrights.data.hasrights[0].hasOwnProperty('update_status') &&
            tblhasrights.data.hasrights[0]['update_status']
        ) {
            if (pageData.state && state.is_edit == false && state.data_assigned == false) {
                //console.log("inside get edit")
                const getData = async () => {
                    try {
                        const result = await getAPI(`courseslot/${pageData.state.edit_id}`, false);
                        if (result && result.statusCode === 200 && result.success === true) {
                            tbldata = result;
                            ////console.log(tbldata)
                            setState((prevState) => {
                                const formatDate = (dateString) => {
                                    if (!dateString) return '';

                                    const date = new Date(dateString);
                                    if (isNaN(date.getTime())) return ''; // Invalid date

                                    const year = date.getFullYear();
                                    const month = String(date.getMonth() + 1).padStart(2, '0');
                                    const day = String(date.getDate()).padStart(2, '0');
                                    return `${year}-${month}-${day}`;
                                };
                                return {
                                    ...prevState,
                                    fields: {
                                        ...prevState.fields,
                                        course_id: tbldata.data.course_id,
                                        institute_id: tbldata.data.institute_id,
                                        slot_on: formatDate(tbldata.data.slot_on),
                                        booking_start_on: formatDate(tbldata.data.booking_start_on),
                                        booking_end_on: formatDate(tbldata.data.booking_end_on),
                                        max_count: tbldata.data.max_count,
                                        total_hours: tbldata.data.total_hours,
                                        is_active: tbldata.data.is_active,
                                        is_status: tbldata.data.is_status
                                    },
                                };
                            });

                            updateState("data_assigned", true);
                        } else {
                            showToast("Something went wrong. Please try again later.");
                        }
                    } catch (err) {
                    }
                };
                getData();
                updateState("is_edit", true);
            }
        }
    }, [tblhasrights, pageData]);
    //#endregion   
    //#region ====================================================== Input Textbox ======================================================
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };

    const handleInputChangemax_count = (event) => {
        const { name, value } = event.target;
        // Validate if the entered value is a number and has a length of 10 or less
        if (/^\d{0,10}$/.test(value)) {
            setState((prevState) => ({
                ...prevState,
                fields: {
                    ...prevState.fields,
                    [name]: value,
                },
            }));
        }
    };

    // ...


    //#endregion
    //#region ====================================================== Checkbox Handler ======================================================
    const handleCheckboxClick = (fieldName) => {
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [fieldName]: !prevState.fields[fieldName],
            },
        }));
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChange = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [event.target.name]: selectedOptions,
            },
        }));
    };
    //#endregion

    //#region ====================================================== Date validation ======================================================
    const handleDateChange = (event) => {
        const { name, value } = event.target;
        const { start_date, end_date } = state.fields;
        const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);
        if (!isValidDate) {
            showToast("Invalid date format");
            return;
        }
        if (name === "start_date" && new Date(value) > new Date(end_date)) {
            showToast("Start date cannot be greater than end date");
            return;
        }
        if (name === "end_date" && new Date(value) < new Date(start_date)) {
            showToast("End date cannot be less than start date");
            return;
        }
        // Update the state
        setState((prevState) => ({
            ...prevState,
            fields: { ...prevState.fields, [name]: value },
        }));
    };
    //#endregion
    //#region ====================================================== Clear or Create/Edit Record ======================================================
    const areObjectsEqual = (obj1, obj2) => {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            const val1 = obj1[key];
            const val2 = obj2[key];

            if (typeof val1 === 'object' && typeof val2 === 'object') {
                if (!areObjectsEqual(val1, val2)) {
                    return false;
                }
            } else if (val1 !== val2) {
                return false;
            }
        }

        return true;
    };
    useEffect(() => {
        if (state.saved === true) {
            //console.log(state);
            if (state.is_edit === false) {
                const saveData = async () => {
                    try {
                        if (!areObjectsEqual(state.oldfields, state.apifields)) {
                            //  const newapiFields = state.apifields;
                            const newapiFields = { ...state.apifields };
                            const result = await postAPI('courseslot', newapiFields, false);
                            if (result && result.statusCode === 201 && result.success === true) {
                                showToast("Record has been added successfully.");
                                clearData();
                            } else {
                                showToast("Something went wrong. Please try again later.");
                            }
                        } else {
                            showToast("Data has not changed. Please add unique records.");
                        }
                    } catch (err) {
                    }
                };
                saveData();
                updateState("saved", false);
            }
            else {
                //console.log("inside edit");
                const editData = async () => {
                    try {
                        //console.log("state.apifields");
                        //console.log(state.apifields);
                        const newapiFields = { ...state.apifields };
                        const result = await putAPI('courseslot', newapiFields, false);
                        if (result && result.statusCode === 200 && result.success === true) {
                            showToast("Record has been updated successfully.");
                            clearData();
                            updateState("saved", false);
                            updateState("is_edit", false);
                            navigate("/courseslotlist");
                        } else {
                            showToast("Something went wrong. Please try again later.");
                        }
                    } catch (err) {

                    }
                };
                editData();


            }
        }
    }, [state.saved]);
    const saveClicked = () => {
        //console.log("state.apifields")

        if (
            state.fields.slot_on &&
            state.fields.total_hours != "0" && state.fields.max_count != "0" &&
            state.fields.course_id && state.fields.course_id != "0"
        ) {
            setState((prevState) => ({
                ...prevState,
                saved: false
            }));
            setState((prevState) => ({
                ...prevState,
                saved: true,
                apifields: {
                    ...prevState.apifields,
                    course_id: prevState.fields.course_id,
                    institute_id: prevState.fields.institute_id,
                    slot_on: prevState.fields.slot_on,
                    booking_start_on: prevState.fields.booking_start_on,
                    booking_end_on: prevState.fields.booking_end_on,
                    max_count: prevState.fields.max_count,
                    total_hours: prevState.fields.total_hours,
                    is_active: prevState.fields.is_active,
                    is_status: prevState.fields.is_status
                },
            }));
        }
        else {
        

            if (!state.fields.slot_on) {
                showToast("Slot start date cannot be empty.");
            }
            else
                if (!state.fields.total_hours) {
                    showToast("Total hours cannot be empty.");
                }
                else if (!state.fields.max_count) {
                    showToast("Total registration count cannot be empty.");
                }
                else if (!state.fields.course_id || state.fields.course_id === "0") {
                    showToast("Please select a valid course.");
                }

        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const debouncedSaveClicked = debounce(saveClicked, 500);
    const clearData = async () => {
        try {
            let role_id = 2;
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                role_id = getUser.role_id;
            }
            const updatedapifieldsState = {
                course_id: "0",
                institute_id: "1",
                slot_on: "",
                booking_start_on: "",
                booking_end_on: "",
                max_count: "0",
                total_hours: "0",
                is_active: true,
                is_status: false
            };
            const updatedfieldState = {
                course_id: "0",
                institute_id: "1",
                slot_on: "",
                booking_start_on: "",
                booking_end_on: "",
                max_count: "0",
                total_hours: "0",
                is_active: true,
                is_status: false

            };
            updateState("oldfields", state.apifields);
            updateState("apifields", updatedapifieldsState);
            updateState("fields", updatedfieldState);
        } catch (err) { }
    };
    //#endregion
    //#region ====================================================== Back or FAQ ======================================================
    const Back = () => {
        try {

            window.scrollTo({ top: 0, behavior: 'smooth' });
            navigate("/courseslotlist");
        } catch (err) { }
    };
    const debouncedBack = debounce(Back, 500);
    //#region ====================================================== Checkbox Handler ======================================================
    const handleCheckboxActiveClick = () => {
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                is_active: !prevState.fields.is_active,
            },
        }));
    };
    const handleCheckboxStatusClick = () => {
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                is_status: !prevState.fields.is_status,
            },
        }));
    };
    //#endregion
    const FAQ = () => {
        try {

            window.scrollTo({ top: 0, behavior: 'smooth' });
            navigate("/FAQ");
        } catch (err) { }
    };
    const debouncedFAQ = debounce(FAQ, 500);
    //#endregion
    //#region ====================================================== Toast Message set ======================================================
    const showToast = (message) => {
        updateState("show_toast", true);
        updateState("toast_msg", message);
        setTimeout(() => {
            updateState("show_toast", false);
        }, 5000);
    };
    const hideToast = () => {
        updateState("show_toast", false);
    };
    //#endregion






    //#region ====================================================== Get API ======================================================
    const getAPI = async (url, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    url += `&user_id=${getUser.user_id}`;
                }
                //console.log("inside GEt")
                //console.log(url)
                const response = await axios.get(`${baseURL.serverUrl}${url}`, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                    },
                });

                //console.log(response.data)
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion
    //#region ====================================================== Post API ======================================================
    const postAPI = async (url, data, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    // Pass user_id through data
                    data.user_id = getUser.user_id;
                }
                //console.log("inside Post data");
                //console.log(data);
                const response = await axios.post(`${baseURL.serverUrl}${url}`, data, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                        'Content-Type': 'application/json',
                    },
                });
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion 
    //#region ====================================================== Post API ======================================================
    const putAPI = async (url, data, Auth) => {
        try {

            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    data.user_id = getUser.user_id;
                }
                //console.log("inside put data");
                //console.log(data);
                const response = await axios.put(`${baseURL.serverUrl}${url}/${pageData.state.edit_id}`, data, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                        'Content-Type': 'application/json',
                    },
                });
                //console.log(response)
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion 
    return (
        <>
            <>
                {/* ====================================================== Head Section ====================================================== */}
                <div className="nk-block-head">
                    <div className="nk-block-head-between flex-wrap gap g-2">
                        {/* ====================================================== Page Name ====================================================== */}
                        <div className="nk-block-head-content">
                            <h2 className="nk-block-title">Course slot Booking</h2>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="#">Course slot Booking</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        Entry
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        {/* ====================================================== Add New record filter export ====================================================== */}
                        <div className="nk-block-head-content">
                            <ul className="d-flex">
                                {tblhasrights &&
                                    tblhasrights.data &&
                                    Array.isArray(tblhasrights.data.hasrights) &&
                                    tblhasrights.data.hasrights.length > 0 &&
                                    tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                                    tblhasrights.data.hasrights[0]["view_status"] && (
                                        <>
                                            {!state.show_filter && (
                                                <>
                                                    <li>
                                                        <a
                                                            className="btn btn-outline-light btn-md d-md-inline-flex mr10"
                                                            onClick={debouncedFAQ}
                                                        >
                                                            <em className="icon ni ni-info"></em>
                                                            <span>FAQ</span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="btn btn-primary d-none d-md-inline-flex"
                                                            onClick={debouncedBack}
                                                        >
                                                            <em className="icon ni ni-arrow-left"></em>
                                                            <span>Back</span>
                                                        </a>
                                                    </li>
                                                </>
                                            )}

                                        </>
                                    )}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* ====================================================== Body section ====================================================== */}
                <div className="nk-block">
                    <ul className="nav nav-tabs mb-3 nav-tabs-s1 pos-sticky">
                        <li className="nav-item">    <a className="nav-link active" href="#summary">Summary</a>  </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="summary">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h5 className="summary">Summary</h5>
                                            <p className="ptitle">Enter essential course slot details, including slot starting date, booking start date, booking ending date, and other information to user on website.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className="row" style={{ display: "none" }}>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="tax-class" className="form-label">Course</label>
                                                <div className="form-control-wrap">
                                                    <Select2
                                                        data={[
                                                            { text: "select an option", id: 0 },
                                                            ...(tblcourses && tblcourses.data && tblcourses.data.hasOwnProperty("courses") && tblcourses.data.courses || []).map((x) => ({
                                                                text: x.course_name,
                                                                id: x.course_id,
                                                                key: x.course_id.toString(),
                                                            })),
                                                        ]}
                                                        options={{
                                                            placeholder: "select an option",
                                                        }}
                                                        name="course_id"
                                                        className="form-control"
                                                        value={state.fields.course_id}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Slot date</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="date"
                                                        name="slot_on"
                                                        className="form-control"
                                                        value={state.fields.slot_on}
                                                        onChange={handleDateChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Slot booking start date</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="date"
                                                        name="booking_start_on"
                                                        className="form-control"
                                                        value={state.fields.booking_start_on}
                                                        onChange={handleDateChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Slot booking end date</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="date"
                                                        name="booking_end_on"
                                                        className="form-control"
                                                        value={state.fields.booking_end_on}
                                                        onChange={handleDateChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Total course hours</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter total course hours"
                                                        name="total_hours"
                                                        className="form-control"
                                                        value={state.fields.total_hours}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Maximum slot registration count</label>
                                                <div className="form-control-wrap">
                                                    <input
                                                        type="text"
                                                        placeholder="Enter maximum slot registration count"
                                                        name="max_count"
                                                        className="form-control"
                                                        value={state.fields.max_count}
                                                        onChange={handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Active status</label>
                                                <div className="form-control-wrap">
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={state.fields.is_active}
                                                            onChange={handleCheckboxActiveClick}
                                                            value=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="form-label">Set limited seats status</label>
                                                <div className="form-control-wrap">
                                                    <div className="form-check form-switch">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            checked={state.fields.is_status}
                                                            onChange={handleCheckboxStatusClick}
                                                            value=""
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div >
                </div >
                {/* ====================================================== Footer Section ====================================================== */}
                < div className="divbottom" >
                    <div className="bottombutton ">
                        <ul className="d-flex align-items-center gap g-3">
                            <li>
                                <a
                                    className="btn  btn-outline-light"
                                    onClick={debouncedBack}
                                >
                                    <em className="icon ni ni-arrow-left"></em>
                                    <span>Cancel</span>
                                </a>
                            </li>
                            {tblhasrights &&
                                tblhasrights.data &&
                                Array.isArray(tblhasrights.data.hasrights) &&
                                tblhasrights.data.hasrights.length > 0 &&
                                tblhasrights.data.hasrights[0].hasOwnProperty("write_status") &&
                                tblhasrights.data.hasrights[0]["write_status"] && (
                                    <>
                                        <li> <button
                                            type="button"
                                            className="btn btn-primary"
                                            name="btnsave"
                                            id="btnsave"
                                            onClick={debouncedSaveClicked}
                                        >
                                            <span>{state.is_edit ? 'Update record' : 'Create record'}</span>
                                        </button> </li>
                                    </>
                                )}


                        </ul>
                    </div>
                </div >
                {/* ====================================================== Toast message ====================================================== */}
                {
                    state.show_toast && (
                        <div
                            className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
                            role="alert"
                            aria-live="assertive"
                            aria-atomic="true"
                        >
                            <div className="d-flex">
                                <div className="toast-body">{state.toast_msg}</div>
                                <button
                                    type="button"
                                    className="btn-close btn-close-white me-2 m-auto"
                                    data-bs-dismiss="toast"
                                    onClick={(e) => hideToast()}
                                    aria-label="Close"
                                ></button>
                            </div>
                        </div>
                    )
                }
            </>
        </>
    );
};
export default adminLayout(CourseSlotBookingEntry);