import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import logoimage from "../assets/images/vasu.png";
import Select2 from "react-select2-wrapper";
import faviconimg from "../images/favicon.png";
import axios from "axios";
import baseURL from "../common/base-urls.js";
import "react-select2-wrapper/css/select2.css";
import debounce from "lodash/debounce";
import useSWR, { mutate } from "swr";
import adminLayout from "../hoc/adminLayout.js";
import { useNavigate, useLocation } from "react-router-dom";
import { update } from "lodash";

const SetPassword = () => {
  const navigate = useNavigate();
  const pageData = useLocation();
  const [apiUrl, setApiUrl] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  useEffect(() => {
    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    //console.log(`=======================================`);
    //console.log(`========= Updated on ${formattedTime} =========`);
    //console.log(`=======================================`);
    //console.log(pageData);
    return () => {
    };
  }, [pageData]);
  //#region ====================================================== Create State =====================================================
  const [state, setState] = useState({
    show_toast: false,
    toast_msg: "No record found",
    isregister: false,
    step: "0",
    fields: {
      phone: "",
      otp: "",
      password: "",
      oldpassword: "",
      confirmpassword: ""
    },
    apifields: {
      phone: "",
      otp: "",
      oldpassword: "",
      password: "",
      confirmpassword: ""
    },
    oldfields: {
      phone: "",
      otp: "",
      oldpassword: "",
      password: "",
      confirmpassword: ""
    },
  });
  //#region ====================================================== Input Textbox ======================================================
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }));
  };
  //#region ====================================================== Update State ======================================================
  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const clearData = async () => {
    try {
      const updatedapifieldsState = {
        phone: "",
        oldpassword: "",
        otp: "",
        password: "",
        confirmpassword: ""
      };
      const updatedfieldState = {
        phone: "",
        oldpassword: "",
        otp: "",
        password: "",
        confirmpassword: ""
      };
      updateState("oldfields", state.apifields);
      updateState("apifields", updatedapifieldsState);
      updateState("fields", updatedfieldState);
    } catch (err) { }
  };
  //#region ====================================================== Toast Message set ======================================================
  const showToast = (message) => {
    updateState("show_toast", true);
    updateState("toast_msg", message);
    setTimeout(() => {
      updateState("show_toast", false);
    }, 5000);
  };
  const hideToast = () => {
    updateState("show_toast", false);
  };
  //#endregion

  const areObjectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      if (typeof val1 === 'object' && typeof val2 === 'object') {
        if (!areObjectsEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }

    return true;
  };
  useEffect(() => {
    const saveData = async () => {
      if (state.step == "5") {
        setLoading(true);
        try {
          const newapiFields = { ...state.apifields };
          const dataforregister = {
            "oldpassword": newapiFields.oldpassword,
            "password": newapiFields.password
          };
          const result = await putAPI('user/updatePassword', dataforregister, true);
          if (result && result.statusCode === 200 && result.success === true) {
            setState((prevState) => ({
              ...prevState,
              step: "2"
            }));
            localStorage.removeItem("token");
            localStorage.removeItem("user_id");
            localStorage.removeItem("institute_id");
            localStorage.removeItem("user");
            localStorage.removeItem("designation_id");
            localStorage.removeItem("username");
            navigate(`/`, {});
          } else {
            setState((prevState) => ({
              ...prevState,
              step: "0"
            }));
            showToast("Current password does not matched or Try another new password.");
          }

        } catch (err) {
          showToast(err);
          setLoading(false);
        }
        setLoading(false);
      }
    };
    saveData();
    updateState("isregister", false);
  }, [state.step]);

  const updateClicked = () => {
    // if (state.fields.phone.length == 10 && state.fields.first_name != "" && state.fields.qualification != "" && state.fields.last_name != "" && state.fields.title != "" && state.fields.designation_id != "" && state.fields.password.length > 5 && state.fields.password == state.fields.confirmpassword) {
    //   if (state.fields.designation_id == "6" && state.fields.organisation == "") {
    //     showToast("Organisation can not be empty");
    //   }
    //   else {
    if (state.fields.password.length > 5 && state.fields.oldpassword.length > 5 && state.fields.password == state.fields.confirmpassword) {
      setState((prevState) => ({
        ...prevState,
        step: "5",
        apifields: {
          ...prevState.apifields,
          oldpassword: prevState.fields.oldpassword,
          password: prevState.fields.password,
          confirmpassword: prevState.fields.confirmpassword
        },
      }));
    }
    else {

      showToast("Password and confirm password do not match. Check current password");

    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedUpdate = debounce(updateClicked, 500);




  //#region ====================================================== Get API ======================================================
  const getAPI = async (url, Auth) => {
    try {
      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        if (Auth) {
          url += `&user_id=${getUser.user_id}`;
        }
        const response = await axios.get(`${baseURL.serverUrl}${url}`, {
          headers: {
            "x-access-token": getUser.token,
            Authorization: `Bearer ${getUser.token}`,
          },
        });
        return response.data;
      } else {
        return {};
      }
    } catch (err) {
      return {};
    }
  };
  //#endregion
  //#region ====================================================== Post API ======================================================
  const putAPI = async (url, data, Auth) => {
    try {

      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        if (Auth) {
          data.user_id = getUser.user_id;
        }
        //console.log("inside put data");
        //console.log(data);

        const response = await axios.put(`${baseURL.serverUrl}${url}`, data, {
          headers: {
            "x-access-token": getUser.token,
            Authorization: `Bearer ${getUser.token}`,
            'Content-Type': 'application/json',
          },
        });
        //console.log(response)
        return response.data;
      } else {
        return {};
      }
    } catch (err) {
      return {};
    }
  };
  //#endregion 
  //#region ====================================================== Post API ======================================================
  const postAPI = async (url, data, Auth) => {
    try {
      const response = await axios.post(`${baseURL.serverUrl}${url}`, data, {
        headers: {
          "x-access-token": '',
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (err) {
      return {};
    }
  };
  //#endregion  
  return (
    <>
      <div className="flex  w-full flex-col justify-between">
        <header className="flex items-center justify-between p-4 lg:px-16 lg:py-6 2xl:px-24">

        </header>
        <div className="flex w-full flex-col justify-center px-5">
          <div className="mx-auto w-full max-w-md md:max-w-lg lg:max-w-xl 2xl:pb-8 ">
            <div className="flex flex-col items-center">
              <h2 className="rizzui-title-h2 mb-7 text-center text-[28px] font-bold leading-snug md:text-3xl md:!leading-normal lg:mb-10 lg:text-4xl logtitle">Set New Password<br /></h2>
            </div>
            <form noValidate="">
              <div className="space-y-5 lg:space-y-6">
                <div className="form-group">
                  <label htmlFor="productname" className="form-label">Current Password</label>
                  <div className="form-control-wrap">
                    <input name="oldpassword" type="password" className="form-control  py-2 h-14  x-5" id="txtpassword" placeholder="Enter old password" value={state.fields.oldpassword}
                      onChange={handleInputChange} />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="productname" className="form-label">New Password</label>
                  <div className="form-control-wrap">
                    <input name="password" type="password" className="form-control  py-2 h-14  x-5" id="txtpassword" placeholder="Enter password" value={state.fields.password}
                      onChange={handleInputChange} />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="productname" className="form-label">Confirm New Password</label>
                  <div className="form-control-wrap">
                    <input name="confirmpassword" type="password" className="form-control  py-2 h-14  x-5" id="txtconfirmpassword" placeholder="Enter password again" value={state.fields.confirmpassword}
                      onChange={handleInputChange} />
                  </div>
                </div>
                <p className="mt-6 text-center text-[15px] leading-loose text-gray-500 md:mt-7 lg:mt-9 lg:text-sm">
                  Password should be more than 5 characters.
                </p>
                <button type="button" className="rizzui-button inline-flex font-medium items-center justify-center active:enabled:translate-y-px focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-50 transition-colors duration-200 px-8 py-2.5 text-base h-14 rounded-md border border-transparent focus-visible:ring-offset-2 bg-gray-900 hover:enabled::bg-gray-800 active:enabled:bg-gray-1000 focus-visible:ring-gray-900/30 text-gray-0 w-full"
                  id="btnupdate" onClick={debouncedUpdate}> {loading ? 'Updating password...' : 'Update password'}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* ====================================================== Toast message ====================================================== */}
      {state.show_toast && (
        <div
          className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="d-flex">
            <div className="toast-body">{state.toast_msg}</div>
            <button
              type="button"
              className="btn-close btn-close-white me-2 m-auto"
              data-bs-dismiss="toast"
              onClick={(e) => hideToast()}
              aria-label="Close"
            ></button>
          </div>
        </div>
      )}


    </>
  );
};

export default adminLayout(SetPassword);
