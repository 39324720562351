import React, { useState, useEffect, useCallback } from "react";
import adminLayout from "../hoc/adminLayout";
import Select2 from "react-select2-wrapper";
import "react-select2-wrapper/css/select2.css";
import emptyrecord from "../assets/images/empty.png";
import debounce from "lodash/debounce";
import baseURL from "../common/base-urls.js";
import axios from "axios";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";
const DesignationList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [apiUrl, setApiUrl] = useState('');
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    useEffect(() => {
        const currentTime = new Date();
        const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        //console.log(`=======================================`);
        //console.log(`========= Updated on ${formattedTime} =========`);
        //console.log(`=======================================`);
        //console.log(location);
        return () => {
        };
    }, [location]);
    //#region ====================================================== Create State =====================================================
    const [state, setState] = useState({
        delete_id: "0",
        lblpagedisplay: 0,
        total_pages: 0,
        pagination: [],
        show_filter: false,
        filter_applied: false,
        show_toast: false,
        toast_msg: "No record found",
        show_modal: false,
        modal_title: "Confirmation",
        modal_msg:
            "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
        modal_button: "Delete",
        fields: {
            designation_name: "",
        },
        apifields: {
            designation_name: "",
        },
    });
    //#endregion
    //#region ====================================================== Update State ======================================================
    const updateState = (key, value) => {
        setState((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    //#endregion
    //#region ====================================================== Page Rights ======================================================
    const rightsURL = `user/chkuserrights?form_id=${12}`;
    const {
        data: tblhasrights,
        error: error1,
        isValidating: isValidating2,
    } = useSWR(rightsURL, () => getAPI(rightsURL, true), {
        revalidateOnReconnect: false,
        revalidateOnFocus: false,
    });
    //#endregion
    //#region ====================================================== Page Table Bind Data ======================================================
    let tbldata = {};
    let tblpagination = {};
    useEffect(() => {
        if (
            tblhasrights &&
            tblhasrights.data &&
            Array.isArray(tblhasrights.data.hasrights) &&
            tblhasrights.data.hasrights.length > 0 &&
            tblhasrights.data.hasrights[0].hasOwnProperty('view_status') &&
            tblhasrights.data.hasrights[0]['view_status']
        ) {
            const queryParams = {
                page: page,
                limit: limit,
                designation_name: state.apifields.designation_name
            };
            // Filter out null or blank parameters
            const filteredParams = Object.fromEntries(
                Object.entries(queryParams).filter(([_, value]) => value != null && value !== '')
            );
            // Convert the filtered parameters into a query string
            const queryString = new URLSearchParams(filteredParams).toString();
            // Construct the final apiUrl
            const newApiUrl = `designation?${queryString}`;
            setApiUrl(newApiUrl);
            if (!state.show_filter || !state.filter_applied) {
                return;
            }
            if (queryString && queryString != `page=1&limit=${limit}`) {
            } else {
                showToast("Please enter/select at least one filter criteria to apply the filter.");
            }
        }
    }, [tblhasrights, state.apifields, state.show_filter, page, limit]);
    const { data, error, isValidating } = useSWR(
        apiUrl,
        () => getAPI(apiUrl, false),
        {
            revalidateOnReconnect: false,
            revalidateOnFocus: false,
        }
    );
    tbldata = data;
    //#endregion
    //#region ====================================================== Pagination & Page Changed ======================================================
    useEffect(() => {
        try {
            if (tbldata && tbldata.data && tbldata.data.hasOwnProperty("count") && tbldata.data.count > 0) {
                let dblPageCount = Math.ceil(parseInt(tbldata.data.count) / limit);
                let pageCount = Math.max(1, dblPageCount);
                updateState("total_pages", pageCount);
                let rows = [];
                if (pageCount > 0) {
                    for (let i = 1; i <= pageCount; i++) {
                        if (i == 1 || i == pageCount) {
                            rows.push({ text: i.toString(), value: i.toString(), enabled: i != parseInt(page) });
                        }
                        else if ((i >= parseInt(page) - 2) && (i <= parseInt(page) + 3)) {
                            rows.push({ text: i.toString(), value: i.toString(), enabled: i != parseInt(page) });
                        }
                        else if (((i > parseInt(page) + 3) && (i >= pageCount - 2))) {
                            rows.push({ text: i.toString(), value: i.toString(), enabled: i != parseInt(page) });
                        }
                    }
                } else {
                    pageCount = '0';
                }
                setState((prevState) => ({
                    ...prevState,
                    pagination: rows
                }));
                tblpagination = rows;
                let firstpage = 1;
                let lastpage = 1;
                if (parseInt(page) === 1) {
                    if (limit * parseInt(page) <= parseInt(tbldata.data.count)) {
                        firstpage = 1;
                        lastpage = limit;
                    } else {
                        firstpage = 1;
                        lastpage = parseInt(tbldata.data.count);
                    }
                } else {
                    if (limit * parseInt(page) <= parseInt(tbldata.data.count)) {
                        firstpage = limit * (parseInt(page) - 1) + 1;
                        lastpage = limit * parseInt(page);
                    } else {
                        lastpage = parseInt(tbldata.data.count);
                        firstpage = limit * (parseInt(page) - 1) + 1;
                    }
                }
                updateState("lblpagedisplay", tbldata.data.count !== '0' ? `${firstpage} - ${lastpage}` : '0 - 0');
                if (lastpage === 0) {
                    firstpage = 0;
                    updateState("lblpagedisplay", tbldata.data.count !== '0' ? `${firstpage} - ${lastpage}` : '0 - 0');
                }
            }
        } catch (ex) {
        }
    }, [data]);
    const handlePageChange = (newPage) => {
        setPage(newPage);
    };
    //#endregion
    //#region ====================================================== Input Textbox ======================================================
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [name]: value,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Multi Select2 Dropdown ======================================================
    const handleSelectMultipleChange = (event) => {
        const selectedOptions = Array.from(
            event.target.selectedOptions,
            (option) => option.value
        );
        setState((prevState) => ({
            ...prevState,
            fields: {
                ...prevState.fields,
                [event.target.name]: selectedOptions,
            },
        }));
    };
    //#endregion
    //#region ====================================================== Date validation ======================================================
    const handleDateChange = (event) => {
        const { name, value } = event.target;
        const { start_date, end_date } = state.fields;
        const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);
        if (!isValidDate) {
            showToast("Invalid date format");
            return;
        }
        if (name === "start_date" && new Date(value) > new Date(end_date)) {
            showToast("Start date cannot be greater than end date");
            return;
        }
        if (name === "end_date" && new Date(value) < new Date(start_date)) {
            showToast("End date cannot be less than start date");
            return;
        }
        // Update the state
        setState((prevState) => ({
            ...prevState,
            fields: { ...prevState.fields, [name]: value },
        }));
    };
    //#endregion
    //#region ====================================================== Filter Section ======================================================
    const applyFilter = async () => {
        try {
            setState((prevState) => ({
                ...prevState,
                filter_applied: true,
                apifields: {
                    ...prevState.apifields,
                    designation_name: state.fields.designation_name,
                },
            }));
            setPage(1);
        } catch (err) { }
    };
    const clearFilter = async () => {
        try {
            const updatedapifieldsState = {
                designation_name: "",
            };
            const updatedfieldState = {
                designation_name: "",
            };
            updateState("apifields", updatedapifieldsState);
            updateState("fields", updatedfieldState);
            updateState("filter_applied", false);
        } catch (err) { }
    };
    const showFilter = async () => {
        try {
            updateState("show_filter", true);
        } catch (err) { }
    };
    const hideFilter = async () => {
        try {
            updateState("show_filter", false);
        } catch (err) { }
    };
    //#endregion
    //#region ====================================================== Data Actions Add Edit Delete ======================================================
    const deleteRecord = async () => {
        try {
            const userString = localStorage.getItem("user");
            if (!userString) {
                return;
            }
            const getUser = JSON.parse(userString);
            const response = await axios.delete(
                `${baseURL.serverUrl}designation/${state.delete_id}`,
                {
                    headers: {
                        "x-access-token": "",
                        Authorization: `Bearer ${getUser.token}`,
                    },
                }
            );
            if (response && response.status == "201") {
                hideModal();
                showToast(response.data.message);
            } else {
                setPage(1);
                hideModal();
                showToast("Record deleted successfully"); mutate(apiUrl);
            }
        } catch (err) { }
    };
    const debouncedDeleteRecord = debounce(deleteRecord, 500);
    const editRecord = (designationId) => {
        try {
            navigate("/designationentry", { state: { edit_id: designationId } });
        } catch (err) { }
    };
    const debouncedEditRecord = debounce((designationId) => {
        editRecord(designationId);
    }, 500);
    const addRecord = () => {
        try {
            navigate("/designationentry");
        } catch (err) { }
    };
    const debouncedAddRecord = debounce(addRecord, 500);
    //#endregion
    //#region ====================================================== Toast Message set ======================================================
    const showToast = (message) => {
        updateState("show_toast", true);
        updateState("toast_msg", message);
        setTimeout(() => {
            updateState("show_toast", false);
        }, 5000);
    };
    const hideToast = () => {
        updateState("show_toast", false);
    };
    //#endregion
    //#region ====================================================== Modal Popup ======================================================
    const hideModal = async () => {
        updateState("show_modal", false);
        updateState("modal_title", "");
        updateState("modal_msg", "");
        updateState("modal_button", "");
    };
    //#endregion
    //#region ====================================================== Export File ======================================================
    function modifyUrl(apiUrl, start_date, end_date, is_all, getUser) {
        let url = new URL(`export/${apiUrl}`, baseURL.serverUrl);
        // Remove "page" and "limit" if they exist
        url.searchParams.delete("page");
        url.searchParams.delete("limit");
        // If is_all, remove "start_date" and "end_date"
        if (is_all) {
            url.searchParams.delete("start_date");
            url.searchParams.delete("end_date");
        } else {
            // If not is_all, remove existing "start_date" and "end_date"
            url.searchParams.delete("start_date");
            url.searchParams.delete("end_date");
            // Add new "start_date" and "end_date"
            if (start_date && end_date) {
                url.searchParams.append("start_date", start_date);
                url.searchParams.append("end_date", end_date);
            }
        }
        // Check if the URL already has a "token" parameter
        if (!url.searchParams.has("token") && getUser) {
            // If not, add the "token" parameter
            url.searchParams.append("token", getUser.token);
        }
        return url.toString();
    }
    const downloadFile = async (start_date, end_date, is_all) => {
        try {
            //  let apiUrldesignation = `export/${apiUrl}`;
            let getUser = localStorage.getItem('user');
            if (getUser) {
                getUser = JSON.parse(getUser);
                let downloadURL = modifyUrl(apiUrl, start_date, end_date, is_all, getUser);
                // Fetch the file data
                const response = await fetch(`${downloadURL}`);
                const blob = await response.blob();
                // Create a Blob URL
                const blobUrl = window.URL.createObjectURL(blob);
                // Create a download link
                const link = document.createElement('a');
                link.href = blobUrl;
                const currentDate = new Date();
                const formattedDateTime = currentDate.toLocaleString().replace(/[/\s,:]/g, ''); // Remove special characters from the datetime
                const filename = `designation_${formattedDateTime}.xlsx`; // Concatenate the formatted datetime to the filename
                link.download = filename;
                // Append the link to the document
                document.body.appendChild(link);
                // Simulate a click on the link to trigger the download
                link.click();
                // Remove the link from the document
                document.body.removeChild(link);
                // Clean up the Blob URL
                window.URL.revokeObjectURL(blobUrl);
            }
        } catch (error) {
            console.error(error);
        }
    };
    // Helper functions to get start and end dates
    const todayStartDate = () => {
        const today = new Date();
        return today.toISOString().split('T')[0];
    };
    const todayEndDate = () => todayStartDate();
    const weekStartDate = () => {
        const today = new Date();
        const weekStart = new Date(today);
        weekStart.setDate(today.getDate() - 7);
        return weekStart.toISOString().split('T')[0];
    };
    const weekEndDate = () => todayEndDate();
    const monthStartDate = () => {
        const today = new Date();
        const monthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        return monthStart.toISOString().split('T')[0];
    };
    const monthEndDate = () => todayEndDate();
    const yearStartDate = () => {
        const today = new Date();
        const yearStart = new Date(today.getFullYear(), 0, 1);
        return yearStart.toISOString().split('T')[0];
    };
    
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    const yearEndDate = () => todayEndDate();
    //#endregion
    //#region ====================================================== Get API ======================================================
    const getAPI = async (url, Auth) => {
        try {
            let getUser = localStorage.getItem("user");
            if (getUser) {
                getUser = JSON.parse(getUser);
                if (Auth) {
                    url += `&user_id=${getUser.user_id}`;
                }
                const response = await axios.get(`${baseURL.serverUrl}${url}`, {
                    headers: {
                        "x-access-token": getUser.token,
                        Authorization: `Bearer ${getUser.token}`,
                    },
                });
                return response.data;
            } else {
                return {};
            }
        } catch (err) {
            return {};
        }
    };
    //#endregion
    return (
        <>
            <>
                {/* ====================================================== Head Section ====================================================== */}
                <div className="nk-block-head">
                    <div className="nk-block-head-between flex-wrap gap g-2">
                        {/* ====================================================== Page Name ====================================================== */}
                        <div className="nk-block-head-content">
                            <h2 className="nk-block-title">Designations</h2>
                            <nav>
                                <ol className="breadcrumb breadcrumb-arrow mb-0">
                                    <li className="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>
                                    <li className="breadcrumb-item">
                                        <a href="#">Designations</a>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        List
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        {/* ====================================================== Add New record filter export ====================================================== */}
                        <div className="nk-block-head-content">
                            <ul className="d-flex">
                                {tblhasrights &&
                                    tblhasrights.data &&
                                    Array.isArray(tblhasrights.data.hasrights) &&
                                    tblhasrights.data.hasrights.length > 0 &&
                                    tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                                    tblhasrights.data.hasrights[0]["view_status"] && (
                                        <>
                                            {!state.show_filter && (
                                                <>
                                                    <li>
                                                        <button
                                                            type="button"
                                                            className="btn btn-color-dark d-none d-md-inline-flex  mr10"
                                                            name="btnshowfilter"
                                                            onClick={(e) => showFilter()}
                                                        >
                                                            <em className="icon ni ni-filter"></em>
                                                            <span>Show Filter</span>
                                                        </button>
                                                    </li>

                                                </>
                                            )}
                                            <li>
                                                <div className="btn-group" onMouseEnter={() => setIsDropdownOpen(true)} onMouseLeave={() => setIsDropdownOpen(false)}>
                                                    <button className="btn btn-outline-light btn-md d-md-inline-flex mr10 dropdown-toggle" type="button" id="dropdownMenuButton1" aria-expanded="false" onClick={toggleDropdown}> <em className="icon ni ni-download"></em>
                                                        <span>Export</span>
                                                    </button>
                                                    <ul className={`dropdown-menu dropdown-menu-end setbuttonhover ${isDropdownOpen ? 'show' : ''}`}>
                                                        <li>
                                                            <button
                                                                type="button"
                                                                className="dropdown-item"
                                                                onClick={() => downloadFile(todayStartDate(), todayEndDate(), false)}
                                                            >
                                                                <span>Todays Records</span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                type="button"
                                                                className="dropdown-item"
                                                                onClick={() => downloadFile(weekStartDate(), weekEndDate(), false)}
                                                            >
                                                                <span>This Week's Records</span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                type="button"
                                                                className="dropdown-item"
                                                                onClick={() => downloadFile(monthStartDate(), monthEndDate(), false)}
                                                            >
                                                                <span>This Month's Records</span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                type="button"
                                                                className="dropdown-item"
                                                                onClick={() => downloadFile(yearStartDate(), yearEndDate(), false)}
                                                            >
                                                                <span>This Year's Records</span>
                                                            </button>
                                                        </li>
                                                        <li>
                                                            <button
                                                                type="button"
                                                                className="dropdown-item"
                                                                onClick={() => downloadFile(null, null, true)}
                                                            >
                                                                <span>All Records</span>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </>
                                    )}
                                {tblhasrights &&
                                    tblhasrights.data &&
                                    Array.isArray(tblhasrights.data.hasrights) &&
                                    tblhasrights.data.hasrights.length > 0 &&
                                    tblhasrights.data.hasrights[0].hasOwnProperty("write_status") &&
                                    tblhasrights.data.hasrights[0]["write_status"] && (
                                        <>
                                            <li>
                                                <a
                                                    className="btn btn-primary d-none d-md-inline-flex"
                                                    onClick={debouncedAddRecord}
                                                >
                                                    <em className="icon ni ni-plus"></em>
                                                    <span>Add Designation</span>
                                                </a>
                                            </li>
                                        </>
                                    )}
                            </ul>
                        </div>
                    </div>
                </div>
                {/* ====================================================== Body section ====================================================== */}
                <div className="nk-block">
                    {/* ====================================================== Filter section ====================================================== */}
                    {state.show_filter && (
                        <>
                            <div>
                                <span className="dividerlist  mt-0"></span>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h5 className="summary">
                                            Filter
                                            <span className="float-end">
                                                <button
                                                    type="button"
                                                    className="btn btn-color-danger d-none d-md-inline-flex"
                                                    name="btnhidefilter"
                                                    onClick={(e) => hideFilter()}
                                                >
                                                    <em className="icon ni ni-cross"></em>
                                                    <span>Hide filter</span>
                                                </button>
                                            </span>
                                        </h5>
                                        <p className="ptitle">
                                            Refine your search effortlessly with our advanced
                                            filtering options.
                                        </p>
                                    </div>
                                    <div className="col-md-12 mt-0">
                                        <div className="row">
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label">Designation</label>
                                                    <div className="form-control-wrap">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter designation"
                                                            name="designation_name"
                                                            className="form-control"
                                                            value={state.fields.designation_name}
                                                            onChange={handleInputChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="form-group">
                                                    <button
                                                        type="button"
                                                        className="btn btn-primary d-none d-md-inline-flex mr10"
                                                        name="btnfilter"
                                                        onClick={(e) => applyFilter()}
                                                    >
                                                        <em className="icon ni ni-search"></em>
                                                        <span>Filter</span>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-color-info d-none d-md-inline-flex"
                                                        name="btnclearfilter"
                                                        onClick={(e) => clearFilter()}
                                                    >
                                                        <em className="icon ni ni-delete"></em>
                                                        <span>Clear filter</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span className="dividerlist mt-4 mb-4"></span>
                            </div>
                        </>
                    )}
                    {/* ====================================================== No record found ====================================================== */}
                    <div className="card">
                        {/* ====================================================== Table data ====================================================== */}
                        {tblhasrights &&
                            tblhasrights.data &&
                            Array.isArray(tblhasrights.data.hasrights) &&
                            tblhasrights.data.hasrights.length > 0 &&
                            tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                            tblhasrights.data.hasrights[0]["view_status"] && (
                                <>
                                    {tbldata &&
                                        (!tbldata.data || !tbldata.data.designations?.length) ? (
                                        <>
                                            <div className="norecordcss">
                                                <img
                                                    src={emptyrecord}
                                                    width="330px"
                                                    alt="No record found"
                                                />
                                                <div className="norecordfont">No record found</div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <table
                                                className="datatable-init table"
                                                data-nk-container="table-responsive"
                                            >
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="tb-col">
                                                            <span className="overline-title">
                                                                Designation
                                                            </span>
                                                        </th>
                                                        <th className="tb-col tb-col-end">
                                                            <span className="overline-title">Status</span>
                                                        </th>
                                                        <th
                                                            className="tb-col tb-col-end"
                                                            data-sortable="false"
                                                        >
                                                            <span className="overline-title">Action</span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tbldata &&
                                                        tbldata.data.designations.map((x) => (
                                                            <tr key={x?.designation_id}>
                                                                <td className="tb-col">
                                                                    <span className="curserset"
                                                                        onClick={tblhasrights &&
                                                                            tblhasrights.data &&
                                                                            Array.isArray(tblhasrights.data.hasrights) &&
                                                                            tblhasrights.data.hasrights.length > 0 &&
                                                                            tblhasrights.data.hasrights[0].hasOwnProperty("update_status") &&
                                                                            tblhasrights.data.hasrights[0]["update_status"]
                                                                            ? () => debouncedEditRecord(x?.designation_id)  // Replace 1 with the actual designation_id

                                                                            : undefined
                                                                        }
                                                                    >
                                                                        <div className="media-group">
                                                                            <div className="media media-md media-middle media-circle text-bg-info-soft">
                                                                                <span className="smaller">
                                                                                    {x?.designation_name?.charAt(0)}
                                                                                    {x?.designation_name?.charAt(1)}
                                                                                </span>
                                                                            </div>
                                                                            <div className="media-text">
                                                                                <div className="title">
                                                                                    {x.designation_name}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                </td>
                                                                <td className="tb-col  tb-col-end">
                                                                    <span className="badge text-bg-success-soft">
                                                                        {(() => {
                                                                            let status = x?.is_active
                                                                                ? "Active"
                                                                                : "Inactive";
                                                                            return status;
                                                                        })()}
                                                                    </span>
                                                                </td>
                                                                <td className="tb-col tb-col-end">
                                                                {tblhasrights &&
                                                                                        tblhasrights.data &&
                                                                                        Array.isArray(
                                                                                            tblhasrights.data.hasrights
                                                                                        ) &&
                                                                                        tblhasrights.data.hasrights.length >
                                                                                        0 &&
                                                                                        tblhasrights.data.hasrights[0].hasOwnProperty(
                                                                                            "update_status"
                                                                                        ) &&
                                                                                        tblhasrights.data.hasrights[0][
                                                                                        "update_status"
                                                                                        ] && ( 
                                                                                                <a
                                                                                                    type="button"
                                                                                                    className="actionbtn" onClick={() => debouncedEditRecord(x?.designation_id)}
                                                                                                >
                                                                                                    <em className="icon actioni ni ni-edit"></em>
                                                                                                    
                                                                                                </a> 
                                                                                        )}
                                                                                    {tblhasrights &&
                                                                                        tblhasrights.data &&
                                                                                        Array.isArray(
                                                                                            tblhasrights.data.hasrights
                                                                                        ) &&
                                                                                        tblhasrights.data.hasrights.length >
                                                                                        0 &&
                                                                                        tblhasrights.data.hasrights[0].hasOwnProperty(
                                                                                            "delete_status"
                                                                                        ) &&
                                                                                        tblhasrights.data.hasrights[0][
                                                                                        "delete_status"
                                                                                        ] && ( 
                                                                                                <a
                                                                                                    type="button"
                                                                                                    name="btndeletepop"
                                                                                                    onClick={() => {
                                                                                                        updateState(
                                                                                                            "delete_id",
                                                                                                            x?.designation_id
                                                                                                        );
                                                                                                        updateState(
                                                                                                            "show_modal",
                                                                                                            true
                                                                                                        );
                                                                                                        updateState(
                                                                                                            "modal_title",
                                                                                                            "Confirmation"
                                                                                                        );
                                                                                                        updateState(
                                                                                                            "modal_msg",
                                                                                                            "Are you sure want to delete this record? This action cannot be undone"
                                                                                                        );
                                                                                                        updateState(
                                                                                                            "modal_button",
                                                                                                            "Delete"
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <em className="icon actioni ni ni-trash"></em>
                                                                                                    
                                                                                                </a> 
                                                                                        )}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        </>
                                    )}
                                </>
                            )}
                    </div>
                </div>
                {/* ====================================================== Pagination ====================================================== */}
                {tblhasrights &&
                    tblhasrights.data &&
                    Array.isArray(tblhasrights.data.hasrights) &&
                    tblhasrights.data.hasrights.length > 0 &&
                    tblhasrights.data.hasrights[0].hasOwnProperty("view_status") &&
                    tblhasrights.data.hasrights[0]["view_status"] && (
                        <>
                            {tbldata && tbldata.data && tbldata.data.designations?.length && (
                                <>
                                    <div className="divbottom">
                                        <div className="bottombutton1">
                                            <div className="dataTable-info"> Showing {state.lblpagedisplay} of {tbldata.data.count} records </div>
                                            <nav className="paginations">
                                                <ul className="pagination pagination-s1 flex-wrap">
                                                    {state.pagination && state.pagination.length && state.pagination.map((x) => (
                                                        <>
                                                            <div className="pagination-pedding" key={x.value}>
                                                                <li className={`page-item ${x.enabled ? '' : 'active'}`}>
                                                                    <button className="page-link" onClick={() => handlePageChange(x.value)}>
                                                                        {x.text}
                                                                    </button>
                                                                </li>
                                                            </div>
                                                        </>
                                                    ))}
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </>
                            )}
                        </>)}
                {/* ====================================================== Footer Section ====================================================== */}
                {/* ====================================================== Toast message ====================================================== */}
                {state.show_toast && (
                    <div
                        className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
                        role="alert"
                        aria-live="assertive"
                        aria-atomic="true"
                    >
                        <div className="d-flex">
                            <div className="toast-body">{state.toast_msg}</div>
                            <button
                                type="button"
                                className="btn-close btn-close-white me-2 m-auto"
                                data-bs-dismiss="toast"
                                onClick={(e) => hideToast()}
                                aria-label="Close"
                            ></button>
                        </div>
                    </div>
                )}
                {/* ====================================================== Confirmation popup ====================================================== */}
                {state.show_modal && (
                    <div
                        className="modal fade show display-block modalmargin"
                        id="exampleModalTop"
                        data-bs-keyboard="false"
                        aria-labelledby="scrollableLabel"
                        aria-modal="true"
                        role="dialog"
                    >
                        <div className="modal-dialog modal-dialog-top">
                            <div className="modal-content modalshadow">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="scrollableLabel">
                                        {state.modal_title}
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={(e) => hideModal()}
                                    ></button>
                                </div>
                                <div className="modal-body"> {state.modal_msg} </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={(e) => hideModal()}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-primary"
                                        onClick={debouncedDeleteRecord}
                                    >
                                        {state.modal_button}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        </>
    );
};
export default adminLayout(DesignationList);