import React from "react";

class Footer extends React.Component {
    componentDidMount() {
        var navLinks = document.querySelectorAll('.nav-link');

        // Add a click event listener to each link
        navLinks.forEach(function (link) {
            link.addEventListener('click', function (event) {
                // Remove "active" class from all links
                navLinks.forEach(function (navLink) {
                    navLink.classList.remove('active');
                });

                // Add "active" class to the clicked link
                link.classList.add('active');
            });
        });

        document.querySelectorAll('.nav-link').forEach(function (link) {
            link.addEventListener('click', function (event) {
                event.preventDefault(); // Prevent the default link behavior

                var targetId = this.getAttribute('href').substr(1); // Get the target element's ID

                var targetElement = document.getElementById(targetId); // Get the target element

                if (targetElement) {
                    var offset = 130; // Set your desired offset (in pixels)
                    var targetOffset = targetElement.getBoundingClientRect().top + window.scrollY - offset;

                    window.scrollTo({
                        top: targetOffset,
                        behavior: 'smooth' // Optional: Add smooth scrolling
                    });
                }
            });
        });
    }

    render() {
        return (
            <div className="nk-footer">
                <div className="container-fluid">
                    <div className="nk-footer-wrap">
                        <div className="nk-footer-copyright"> &copy; 2024 vasu. All rights reserved.</div>
                        <div className="nk-footer-links">
                            <ul className="nav nav-sm">
                                <li className="nav-item"><a href="https://vasutsdc.in" className="nav-link">Visit Website</a></li>
                                <li className="nav-item"><a href="https://vasutsdc.in/#/privacy" className="nav-link">Privacy</a></li>
                                <li className="nav-item"><a href="https://vasutsdc.in/#/terms" className="nav-link">Terms</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
