import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import authLayout from "../hoc/authLayout";
import logoimage from "../assets/images/vasu.png";
import faviconimg from "../images/favicon.png";
import axios from "axios";
import baseURL from "../common/base-urls.js";
import "react-select2-wrapper/css/select2.css";
import debounce from "lodash/debounce";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";

const Index = () => {
  const navigate = useNavigate();
  const pageData = useLocation();
  const [apiUrl, setApiUrl] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  useEffect(() => {
    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    //console.log(`=======================================`);
    //console.log(`========= Updated on ${formattedTime} =========`);
    //console.log(`=======================================`);
    //console.log(pageData);
    return () => {
    };
  }, [pageData]);
  //#region ====================================================== Create State =====================================================
  const [state, setState] = useState({
    show_toast: false,
    toast_msg: "No record found",
    islogin: false,
    fields: {
      email: "",
      password: "",
    },
    apifields: {
      email: "",
      password: "",
    },
    oldfields: {
      email: "",
      password: "",
    },
  });
  //#region ====================================================== Input Textbox ======================================================
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }));
  };
  //#region ====================================================== Update State ======================================================
  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const clearData = async () => {
    try {
      const updatedapifieldsState = {
        email: "",
        password: ""
      };
      const updatedfieldState = {
        email: "",
        password: ""
      };
      updateState("oldfields", state.apifields);
      updateState("apifields", updatedapifieldsState);
      updateState("fields", updatedfieldState);
    } catch (err) { }
  };
  //#region ====================================================== Toast Message set ======================================================
  const showToast = (message) => {
    updateState("show_toast", true);
    updateState("toast_msg", message);
    setTimeout(() => {
      updateState("show_toast", false);
    }, 5000);
  };
  const hideToast = () => {
    updateState("show_toast", false);
  };
  //#endregion

  const areObjectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      if (typeof val1 === 'object' && typeof val2 === 'object') {
        if (!areObjectsEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }

    return true;
  };
  useEffect(() => {
    const saveData = async () => {
      if (state.islogin === true) {
        setLoading(true);
        try {
          if (!areObjectsEqual(state.oldfields, state.apifields)) {
            const newapiFields = { ...state.apifields };
            const dataforlogin = {
              "phone": newapiFields.email,
              "apptype": "ts",
              "password": newapiFields.password,
              "fcm_token": "dummyfcmtoken",
              "device_id": "dummydeviceid"
            };
            const result = await postAPI('user/login', dataforlogin, false);
            if (result && result.statusCode === 201 && result.success === true) {
              const userData = result.data;
              localStorage.setItem('token', userData.token);
              localStorage.setItem('user_id', userData.user_id);
              localStorage.setItem('designation_id', userData.designation_id);
              localStorage.setItem('institute_id', userData.institute_id);
              localStorage.setItem('username', userData.title + " " + userData.first_name + " " + userData.last_name);
              localStorage.setItem('user', JSON.stringify(userData));
              clearData();
              if (userData.designation_id == "5" || userData.designation_id == "6") {
                navigate("/bookslot");
              }
              else {
                navigate("/Home");
              }
            } else {
              showToast("Incorrect credentials");
            }
          } else {
            showToast("Data has not changed. Please add unique records.");
          }
        } catch (err) {
          showToast(err);
          setLoading(false);
        }
        setLoading(false);
      }
      else {
        const token = localStorage.getItem('token');
        const designation_id = localStorage.getItem('designation_id');
        const user_id = localStorage.getItem('user_id');
        const institute_id = localStorage.getItem('institute_id');
        if (token && user_id && institute_id) {
          if (designation_id == "5" || designation_id == "6") {
            navigate("/bookslot");
          }
          else {
            navigate("/Home");
          }

        }
      }
    };
    saveData();
    updateState("islogin", false);
  }, [state.islogin]);
  const saveClicked = () => {
    if (state.fields.email.length > 6 && state.fields.password.length > 5) {
      setState((prevState) => ({
        ...prevState,
        islogin: true,
        apifields: {
          ...prevState.apifields,
          email: prevState.fields.email,
          password: prevState.fields.password
        },
      }));
    }
    else {
      showToast("Invalid credentials");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedSaveClicked = debounce(saveClicked, 500);
  const handleInputChangeNumber = (event) => {
    const { name, value } = event.target;
    // Remove non-digit characters from the input value
    const newValue = value.replace(/\D/g, '');
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: newValue,
      },
    }));
  };
  const forgotClicked = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate("/forgotpassword", {});
  };


  //#region ====================================================== Post API ======================================================
  const postAPI = async (url, data, Auth) => {
    try {
      const response = await axios.post(`${baseURL.serverUrl}${url}`, data, {
        headers: {
          "x-access-token": '',
          'Content-Type': 'application/json',
        },
      });
      return response.data;
    } catch (err) {
      return {};
    }
  };
  //#endregion 


  return (
    <>
      <div className="flex min-h-screen w-full flex-col justify-between">
        <header className="flex items-center justify-between p-4 lg:px-16 lg:py-6 2xl:px-24">
        <a href="https://vasutsdc.in/">
            <img alt="vasu" width="155" height="28" decoding="async" data-nimg="1" className="dark:invert logoic" src={logoimage} />
          </a>
          <div className="flex items-center space-x-2 md:space-x-4">
            <a className="inline-flex items-center gap-x-1 rounded-3xl p-2 py-1 text-sm font-medium transition-colors hover:bg-gray-200 md:px-4 md:py-2.5 [&amp;&gt;svg]:w-4 bg-gray-100 text-gray-900 [&amp;&gt;svg]:text-gray-900" >
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 256 256" className="h-4 w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M189.66,122.34a8,8,0,0,1,0,11.32l-72,72a8,8,0,0,1-11.32-11.32L164.69,136H32a8,8,0,0,1,0-16H164.69L106.34,61.66a8,8,0,0,1,11.32-11.32ZM216,32a8,8,0,0,0-8,8V216a8,8,0,0,0,16,0V40A8,8,0,0,0,216,32Z"></path>
              </svg>
              <span>Login</span>
            </a>
          </div>
        </header>
        <div className="flex w-full flex-col justify-center px-5">
          <div className="mx-auto w-full max-w-md md:max-w-lg lg:max-w-xl 2xl:pb-8 ">
            <div className="flex flex-col items-center">
              <h2 className="rizzui-title-h2 mb-7 text-center text-[28px] font-bold leading-snug md:text-3xl md:!leading-normal lg:mb-10 lg:text-4xl logtitle">Welcome Back! <br /> Sign in with your credentials.</h2>
            </div>
            <form noValidate="">
              <div className="space-y-5 lg:space-y-6">
                <div className="form-group">
                  <label htmlFor="productname" className="form-label">Phone</label>
                  <div className="form-control-wrap">
                    <input type="text" name="email" maxLength={10} className="form-control py-2 h-14  x-5" id="txtemail" placeholder="Enter phone number"
                      value={state.fields.email}
                      onChange={handleInputChangeNumber} />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="productname" className="form-label">Password</label>
                  <div className="form-control-wrap">
                    <input name="password" type="password" className="form-control  py-2 h-14  x-5" id="txtpassword" placeholder="Enter password" value={state.fields.password}
                      onChange={handleInputChange} />
                  </div>
                </div>

                <div className="flex items-center justify-content-end pb-1">
                  <a className="h-auto p-0 text-sm font-semibold text-gray-700 underline transition-colors hover:text-primary hover:no-underline pointercursor" onClick={forgotClicked}>Forgot Password?</a>
                </div>

                <button type="button" className="rizzui-button inline-flex font-medium items-center justify-center active:enabled:translate-y-px focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-50 transition-colors duration-200 px-8 py-2.5 text-base h-14 rounded-md border border-transparent focus-visible:ring-offset-2 bg-gray-900 hover:enabled::bg-gray-800 active:enabled:bg-gray-1000 focus-visible:ring-gray-900/30 text-gray-0 w-full"
                  id="btnlogin" onClick={saveClicked}> {loading ? 'Authenticating...' : 'Sign In'}</button>
              </div>
            </form>
            <p className="mt-6 text-center text-[15px] leading-loose text-gray-500 md:mt-7 lg:mt-9 lg:text-base">
              Want to create a new account? <a className="font-semibold text-gray-700 transition-colors hover:text-primary" href={"#/register"} >Register now</a>
            </p>
          </div>
        </div>
        <footer className="footerdisplay flex flex-col-reverse items-center justify-between px-4 py-5 lg:flex-row lg:px-16 lg:py-6 2xl:px-24 2xl:py-10 .footerpadding">
          <div className="text-center leading-relaxed text-gray-500 lg:text-start">
            © Copyright 2024.  <a className="font-medium transition-colors hover:text-primary" >vasu</a>, all rights reserved.
          </div>
          <div className="-mx-2.5 flex items-center justify-end pb-3 font-medium footercenter text-gray-700 lg:w-1/2 lg:pb-0"><a className="px-2.5 py-1.5 transition-colors hover:text-primary" href="https://vasutsdc.in/">Visit Website</a><a className="px-2.5 py-1.5 transition-colors hover:text-primary" href="https://vasutsdc.in/#/privacy">Privacy</a><a className="px-2.5 py-1.5 transition-colors hover:text-primary" href="https://vasutsdc.in/#/terms">Terms</a></div>
        </footer>
      </div>
      {/* ====================================================== Toast message ====================================================== */}
      {state.show_toast && (
        <div
          className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
        >
          <div className="d-flex">
            <div className="toast-body">{state.toast_msg}</div>
            <button
              type="button"
              className="btn-close btn-close-white me-2 m-auto"
              data-bs-dismiss="toast"
              onClick={(e) => hideToast()}
              aria-label="Close"
            ></button>
          </div>
        </div>
      )}


    </>
  );
};

export default authLayout(Index);
