import 'font-awesome/css/font-awesome.min.css';
import './assets/css/style20d4.css?v1.1.2';
// import './assets/css/bootstrap.min.css';  
// import './assets/css/select2.css'; 
// import './assets/js/bundle.js'; 
// import './assets/js/scripts.js'; 
import './assets/js/jquery.min.js';
import './assets/js/bootstrap.min.js';
// import './assets/js/select2.min.js';   
import InstituteList from './pages/instituteList.js'
import Index from './pages/index.js'
import RegisterPage from './pages/register.js'
import ResetPassword from './pages/auth/ResetPassword';
import ProfilePage from './pages/profile/ProfilePage';
import ChangePasswordPage from './pages/profile/ChangePasswordPage';
import UserPreferencesPage from './pages/profile/UserPreferencesPage';
import UserPage from './pages/userList';
import DesignationPage from './pages/designationList';
import AnnouncementPage from './pages/announcementList';
import InstituteSubscriptionPage from './pages/instituteSubscriptionList.js';
import SubscriptionPage from './pages/subscriptionList.js';
import HomeScreen from './pages/default.js';
import UserEntry from './pages/userEntry.js';
import BookSlot from './pages/bookSlot.js';
import DesignationEntry from './pages/designationEntry.js';
import AnnouncementEntry from './pages/announcementEntry.js';
import SubscriptionEntry from './pages/subscriptionEntry.js';
import InstituteEntry from './pages/instituteEntry.js';
import CourseTypePage from './pages/courseTypeList.js';
import CourseTypeEntryPage from './pages/courseTypeEntry.js';
import CoursePage from './pages/courseList.js';
import CourseEntryPage from './pages/courseEntry.js';
import CourseSlotPage from './pages/courseSlotList.js';
import CourseSlotEntryPage from './pages/courseSlotEntry.js';
import InstituteSubscriptionEntry from './pages/instituteSubscriptionEntry.js';
import CourseSlotBookingPage from './pages/courseSlotBookingList.js';
import CourseSlotBookingEntry from './pages/courseSlotBookingEntry.js';
import SetPassword from './pages/setpassword.js';
import ForgotPasswordPage from './pages/forgotpassword.js';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
function App() {
    return (
        <Router>
            <Routes>
                <Route exact path='/' element={<Index />} />
                <Route exact path='/register' element={<RegisterPage />} />
                <Route exact path='/institutelist' element={<InstituteList />} />
                <Route exact path='/reset-password' element={<ResetPassword />} />
                <Route exact path='/profile' element={<ProfilePage />} />
                <Route exact path='/change-password' element={<ChangePasswordPage />} />
                <Route exact path='/preferences' element={<UserPreferencesPage />} />
                <Route exact path='/userlist' element={<UserPage />} />
                <Route exact path='/designationlist' element={<DesignationPage />} />
                <Route exact path='/announcementlist' element={<AnnouncementPage />} />
                <Route exact path='/subscriptionList' element={<SubscriptionPage />} />
                <Route exact path='/userentry' element={<UserEntry />} />
                <Route exact path='/home' element={<HomeScreen />} />
                <Route exact path='/institutesubscriptionlist' element={<InstituteSubscriptionPage />} />
                <Route exact path='/designationentry' element={<DesignationEntry />} />
                <Route exact path='/announcemententry' element={<AnnouncementEntry />} />
                <Route exact path='/subscriptionentry' element={<SubscriptionEntry />} />
                <Route exact path='/coursetypeentry' element={<CourseTypeEntryPage />} />
                <Route exact path='/instituteentry' element={<InstituteEntry />} />
                <Route exact path='/institutesubscriptionentry' element={<InstituteSubscriptionEntry />} />
                <Route exact path='/coursetypelist' element={<CourseTypePage />} />
                <Route exact path='/courselist' element={<CoursePage />} />
                <Route exact path='/courseentry' element={<CourseEntryPage />} />
                <Route exact path='/courseslotlist' element={<CourseSlotPage />} />
                <Route exact path='/courseslotentry' element={<CourseSlotEntryPage />} />
                <Route exact path='/slotBooking' element={<CourseSlotBookingPage />} />
                <Route exact path='/forgotpassword' element={<ForgotPasswordPage />} />
                <Route exact path='/courseslotbookingentry' element={<CourseSlotBookingEntry />} />
                <Route exact path='/bookslot' element={<BookSlot />} />
                <Route exact path='/setpassword' element={<SetPassword />} />
            </Routes>
        </Router>
    )
}
export default App;
