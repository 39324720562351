import React, { useState, useEffect, useCallback } from "react";
import { Link } from 'react-router-dom';
import authLayout from "../hoc/authLayout";
import logoimage from "../assets/images/vasu.png";
import Select2 from "react-select2-wrapper";
import userimage from "../images/userimg.jpg";

import emptyrecord from "../assets/images/empty.png";
import faviconimg from "../images/favicon.png";
import axios from "axios";
import baseURL from "../common/base-urls.js";
import "react-select2-wrapper/css/select2.css";
import debounce from "lodash/debounce";
import useSWR, { mutate } from "swr";
import { useNavigate, useLocation } from "react-router-dom";
import { update } from "lodash";

const BookSlot = () => {
  const navigate = useNavigate();
  const pageData = useLocation();
  const [apiUrl, setApiUrl] = useState('');
  const [slotapiUrl, slotsetApiUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(true);
  const [noofreg, setnoofreg] = useState(true);
  const [activeTab, setActiveTab] = useState('joinCourse');
  const [mycourse, setMyCourse] = useState(false);
  const [mytrans, setMyTrans] = useState(false);
  const [showBelowCode, setShowBelowCode] = useState(false);
  const [selectionCode, setSelectionCode] = useState(false);
  const [checkoutCode, setCheckoutCode] = useState(false);
  const [showUserCode, setShowUserCode] = useState(false);
  const [showRateSection, setShowRateSection] = useState(false);
  const [showTransactionSection, setShowTransactionSection] = useState(false);
  const [showTransactionCheckout, setShowTransactionCheckout] = useState(false);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [selectedSlotId, setSelectedSlotId] = useState(null);
  const [tblmycourse, setTblMyCourse] = useState(null);
  const [tblmytrans, setTblMyTrans] = useState(null);
  const [selectedOption, setSelectedOption] = useState(''); // State to track selected option


  useEffect(() => {
    const currentTime = new Date();
    const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    //console.log(`=======================================`);
    //console.log(`========= Updated on ${formattedTime} =========`);
    //console.log(`=======================================`);
    //console.log(pageData);
    return () => {
    };
  }, [pageData]);
  //#region ====================================================== Create State =====================================================
  const [state, setState] = useState({
    show_toast: false,
    toast_msg: "No record found",
    show_modal: false,
    saved: false,
    checkbeforepayment: false,
    applycoupon: false,
    is_edit: false,
    user_name: "",
    show_price: "N/A",
    price_list: [],
    noofreg_list: [],
    price_list_id: [],
    price_list_id_p: [],
    final_amount: 0,
    noofreg: 1,
    noofregselected: 0,
    discount_amount: 0,
    studorprof: 1,
    data_assigned: false,
    modal_title: "Confirmation",
    modal_msg:
      "Are you absolutely certain you want to delete this record? This action cannot be reversed.",
    modal_button: "Delete",
    rate_now: {
      course_id: "",
      booking_id: "",
      review_id: "",
      slot_id: "",
      course_name: "",
      rating: 0,
      review: "",
    },
    course: {
      course_id: "",
      course_name: "",
      course_key: ""
    },
    user_fields: {
      selected_price: "",
      firstname: "",
      lastname: "",
      mobileno: "",
      email: "",
    },
    user: [],
    transuser: [],
    on_success: {
      course_name: "",
      student_name: "",
      amount: 0,
      is_success: false,
      order_id: "",
      payment_id: "",
      slot_on: "",
      date: "",
      payment_method: "",
      booking_count: ""
    },
    mycourse_trans: {
      course_name: "",
      student_name: "",
      amount: 0,
      is_success: false,
      order_id: "",
      payment_id: "",
      slot_on: "",
      date: "",
      payment_method: "",
      booking_count: ""
    },
    course_slot: {
      course_id: "",
      course_name: "",
      course_key: "",
      slot_id: "",
      slot_on: "",
      booking_type: "",
      amount: "",
      users: "",
    },
    fields: {
      designation_name: "",
      is_active: true,
      selected_price: "0",
      coupon_code: ""
    },
    apifields: {
      designation_name: "",
      is_active: true,
      selected_price: "0",
      coupon_code: ""
    },
    oldfields: {
      designation_name: "",
      user_name: "",
      is_active: true,
      selected_price: "0",
      coupon_code: ""
    },
  });
  //#endregion


  // Event handler for radio button change
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };



  //#region ====================================================== Shortcuts =====================================================
  document.addEventListener('keydown', function (event) {
    // Check if the key combination is Ctrl + S
    if (event.ctrlKey && event.key === 's') {
      // Prevent the default browser save behavior
      event.preventDefault();
      // Trigger the click event on your save button
      var saveButton = document.getElementById('btnsave');
      if (saveButton) {
        saveButton.click();
      }
    }
  });
  //#endregion
  //#region ====================================================== Update State ======================================================
  const updateState = (key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  //#endregion 
  //#region ====================================================== Create or Edit Record ======================================================
  let tbldata = {};
  useEffect(() => {
    const getData = async () => {
      try {
        let getUser = localStorage.getItem("username");
        if (getUser) {
          updateState("user_name", getUser);
        }
        setSelectedOption("student");
        const newApiUrl = `course/dropdown?institute_id=1`;
        setApiUrl(newApiUrl);

        // const result = await getAPI(`course/dropdown?institute_id=1`, false);
        // if (result && result.statusCode === 200 && result.success === true) {
        //   tbldata = result;
        // } else {
        //   showToast("Something went wrong. Please try again later.");
        // }
      } catch (err) {
      }
    };
    getData();

  }, []);
  const { data, error, isValidating } = useSWR(
    apiUrl,
    () => getAPI(apiUrl, false),
    {
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
    }
  );
  tbldata = data;
  //#endregion 
  //#region ====================================================== Input Textbox ======================================================
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [name]: value,
      },
    }));
  };
  const handleTextareaChange = (event) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      rate_now: {
        ...prevState.rate_now,
        review: value,
      },
    }));
  };
  //#endregion
  //#region ====================================================== Checkbox Handler ======================================================
  const handleCheckboxActiveClick = () => {
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        is_active: !prevState.fields.is_active,
      },
    }));
  };
  //#endregion
  //#region ====================================================== Multi Select2 Dropdown ======================================================
  const handleSelectMultipleChange = (event) => {
    const selectedOptions = Array.from(
      event.target.selectedOptions,
      (option) => option.value
    );
    setState((prevState) => ({
      ...prevState,
      fields: {
        ...prevState.fields,
        [event.target.name]: selectedOptions,
      },
    }));
  };
  //#endregion
  //#region ====================================================== Date validation ======================================================
  const handleDateChange = (event) => {
    const { name, value } = event.target;
    const { start_date, end_date } = state.fields;
    const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(value);
    if (!isValidDate) {
      showToast("Invalid date format");
      return;
    }
    if (name === "start_date" && new Date(value) > new Date(end_date)) {
      showToast("Start date cannot be greater than end date");
      return;
    }
    if (name === "end_date" && new Date(value) < new Date(start_date)) {
      showToast("End date cannot be less than start date");
      return;
    }
    // Update the state
    setState((prevState) => ({
      ...prevState,
      fields: { ...prevState.fields, [name]: value },
    }));
  };
  //#endregion
  //#region ====================================================== Clear or Create/Edit Record ======================================================
  const areObjectsEqual = (obj1, obj2) => {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const val1 = obj1[key];
      const val2 = obj2[key];

      if (typeof val1 === 'object' && typeof val2 === 'object') {
        if (!areObjectsEqual(val1, val2)) {
          return false;
        }
      } else if (val1 !== val2) {
        return false;
      }
    }

    return true;
  };
  useEffect(() => {
    if (state.saved === true) {
      if (state.is_edit === false) {
        const saveData = async () => {
          try {
            //console.log("state.oldfields")
            //console.log(state.oldfields)
            //console.log("state.apifields")
            //console.log(state.apifields)
            if (!areObjectsEqual(state.oldfields, state.apifields)) {
              //  const newapiFields = state.apifields;
              const newapiFields = { ...state.apifields };
              const result = await postAPI('designation', newapiFields, true);
              if (result && result.statusCode === 201 && result.success === true) {
                showToast("Record has been added successfully.");
                clearData();
              } else {
                // showToast("Something went wrong. Please try again later.");
              }
            } else {
              showToast("Data has not changed. Please add unique records.");
            }
          } catch (err) {
          }
        };
        saveData();
        updateState("saved", false);
      }
      else {

        const editData = async () => {
          try {
            //console.log("state.apifields");
            //console.log(state.apifields);
            const newapiFields = { ...state.apifields };
            const result = await putAPI('designation', newapiFields, false);
            if (result && result.statusCode === 200 && result.success === true) {
              showToast("Record has been updated successfully.");
              clearData();
            } else {
              // showToast("Something went wrong. Please try again later.");
            }

          } catch (err) {
          }
        };
        editData();
        updateState("saved", false);
        updateState("is_edit", false);
        navigate("/designationlist");
      }
    }
  }, [state.saved]);
  const saveClicked = () => {
    if (state.fields.designation_name) {
      setState((prevState) => ({
        ...prevState,
        saved: false
      }));

      setState((prevState) => ({
        ...prevState,
        saved: true,
        apifields: {
          ...prevState.apifields,
          designation_name: prevState.fields.designation_name,
          is_active: prevState.fields.is_active
        },
      }));
    }
    else {
      showToast("Designation title cannot be empty.");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedSaveClicked = debounce(saveClicked, 500);
  const clearData = async () => {
    try {
      const updatedapifieldsState = {
        designation_name: "",
        is_active: true
      };
      const updatedfieldState = {
        designation_name: "",
        is_active: true
      };
      //console.log(state.apifields)
      updateState("oldfields", state.apifields);
      updateState("apifields", updatedapifieldsState);
      updateState("fields", updatedfieldState);
    } catch (err) { }
  };
  //#endregion
  //#region ====================================================== Back or FAQ ======================================================
  const Back = () => {
    try {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate("/designationlist");
    } catch (err) { }
  };
  const debouncedBack = debounce(Back, 500);
  const FAQ = () => {
    try {

      window.scrollTo({ top: 0, behavior: 'smooth' });
      navigate("/FAQ");
    } catch (err) { }
  };
  const debouncedFAQ = debounce(FAQ, 500);
  //#endregion
  //#region ====================================================== Toast Message set ======================================================
  const showToast = (message) => {
    updateState("show_toast", true);
    updateState("toast_msg", message);
    setTimeout(() => {
      updateState("show_toast", false);
    }, 5000);
  };
  const hideToast = () => {
    updateState("show_toast", false);
  };
  //#endregion
  //#region ====================================================== Get API ======================================================
  const getAPI = async (url, Auth) => {
    try {
      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        if (Auth) {
          url += `&user_id=${getUser.user_id}`;
        }
        const response = await axios.get(`${baseURL.serverUrl}${url}`, {
          headers: {
            "x-access-token": getUser.token,
            Authorization: `Bearer ${getUser.token}`,
          },
        });
        return response.data;
      } else {
        return {};
      }
    } catch (err) {
      return {};
    }
  };
  //#endregion
  //#region ====================================================== Post API ======================================================
  const postAPI = async (url, data, Auth) => {
    try {
      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        if (Auth) {
          // Pass user_id through data
          data.user_id = getUser.user_id;
        }
        const response = await axios.post(`${baseURL.serverUrl}${url}`, data, {
          headers: {
            "x-access-token": getUser.token,
            Authorization: `Bearer ${getUser.token}`,
            'Content-Type': 'application/json',
          },
        });
        return response.data;
      } else {
        return {};
      }
    } catch (err) {
      return {};
    }
  };
  //#endregion 
  //#region ====================================================== Put API ======================================================
  const putAPI = async (url, data, Auth) => {
    try {
      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        if (Auth) {
          data.user_id = getUser.user_id;
        }
        //console.log("inside put data");
        //console.log(data);
        const response = await axios.put(`${baseURL.serverUrl}${url}/${pageData.state.edit_id}`, data, {
          headers: {
            "x-access-token": getUser.token,
            Authorization: `Bearer ${getUser.token}`,
            'Content-Type': 'application/json',
          },
        });
        return response.data;
      } else {
        return {};
      }
    } catch (err) {
      return {};
    }
  };
  const putAllAPI = async (url, data, Auth) => {
    try {
      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        if (Auth) {
          data.user_id = getUser.user_id;
        }
        //console.log("inside put data");
        //console.log(data);
        const response = await axios.put(`${baseURL.serverUrl}${url}/${state.rate_now.review_id}`, data, {
          headers: {
            "x-access-token": getUser.token,
            Authorization: `Bearer ${getUser.token}`,
            'Content-Type': 'application/json',
          },
        });
        return response.data;
      } else {
        return {};
      }
    } catch (err) {
      return {};
    }
  };


  //#endregion 
  //#region ====================================================== Put API ======================================================

  const handleCustomCourseClick = (response_id) => {
    setShowTransactionDetails(true);
  };

  const handleTransCancelIconClick = () => {
    setSelectedSlotId(null);
    setShowBelowCode(false);
    setSelectionCode(false);
    setShowTransactionCheckout(false);
  };

  const handleRateNowClick = (course_id, course_name, booking_id, review_id, slot_id, rating, review) => {

    setState((prevState) => ({
      ...prevState,
      rate_now: {
        course_id: course_id,
        booking_id: booking_id,
        review_id: review_id,
        slot_id: slot_id,
        course_name: course_name,
        rating: rating,
        review: review,
      }
    }));
    setShowRateSection(true);
  };

  const handleViewTransactionClick = (response_id) => {
    setShowTransactionSection(true);
    setShowRateSection(false);
  };

  const handleMyCancelIconClick = () => {
    setShowRateSection(false);
    setShowTransactionSection(false);
    setState((prevState) => ({
      ...prevState,
      transuser: [],
      mycourse_trans: {
        course_name: "",
        student_name: "",
        amount: 0,
        is_success: false,
        order_id: "",
        payment_id: "",
        slot_on: "",
        date: "",
        payment_method: "",
        booking_count: ""
      }
    }));
  };

  const handleMyTransCancelIconClick = () => {
    setShowRateSection(false);
    setShowTransactionSection(false);
  };

  let tblslot = {};
  useEffect(() => {
    if (state.course.course_id != "") {

      const getData = async () => {
        try {
          //console.log("inside slot");
          const newApiUrl = `courseslot/dropdown?institute_id=1` + "&course_id=" + state.course.course_id;
          slotsetApiUrl(newApiUrl);
          setPrice(false);
        } catch (err) {
        }
      };
      getData();

    }
  }, [state.course]);


  const { data: dataslot, errorslot, isValidatingslot } = useSWR(
    slotapiUrl,
    () => getAPI(slotapiUrl, false),
    {
      revalidateOnReconnect: false,
      revalidateOnFocus: false,
    }
  );
  tblslot = dataslot;

  useEffect(() => {
    if (price === false && tblslot && tblslot.data && tblslot.data.coursedetails) {
      const getData = async () => {
        try {
          let ddprice = [
            { "ddname": "As Student", "ddvalue": 1 },
            { "ddname": "As Professional", "ddvalue": 2 },
            { "ddname": "For Professional Group of 2 to 3", "ddvalue": 3 },
            { "ddname": "For Professional Group of 4 to 6", "ddvalue": 4 },
            { "ddname": "For Professional Group of 7 to 9", "ddvalue": 5 },
            { "ddname": "For Professional Group more than 10", "ddvalue": 6 },
            { "ddname": "For Student Group of 2 to 3", "ddvalue": 7 },
            { "ddname": "For Student Group of 4 to 6", "ddvalue": 8 },
            { "ddname": "For Student Group of 7 to 9", "ddvalue": 9 },
            { "ddname": "For Student Group more than 10", "ddvalue": 10 },
          ];

          let ddnoofreg = [
            { "ddname": "1", "ddvalue": 1 },
            { "ddname": "2", "ddvalue": 2 },
            { "ddname": "3", "ddvalue": 3 },
            { "ddname": "4", "ddvalue": 4 },
            { "ddname": "5", "ddvalue": 5 },
            { "ddname": "6", "ddvalue": 6 },
            { "ddname": "7", "ddvalue": 7 },
            { "ddname": "8", "ddvalue": 8 },
            { "ddname": "9", "ddvalue": 9 },
            { "ddname": "10", "ddvalue": 10 },
            { "ddname": "11", "ddvalue": 11 },
            { "ddname": "12", "ddvalue": 12 },
          ];

          updateState("price_list", ddprice);
          updateState("noofreg_list", ddnoofreg);


          let ddprice1 = [
            { "ddname": "1", "ddvalue": tblslot.data.coursedetails.s_amount },
            { "ddname": "2", "ddvalue": tblslot.data.coursedetails.s3_amount },
            { "ddname": "3", "ddvalue": tblslot.data.coursedetails.s3_amount },
            { "ddname": "4", "ddvalue": tblslot.data.coursedetails.s6_amount },
            { "ddname": "5", "ddvalue": tblslot.data.coursedetails.s6_amount },
            { "ddname": "6", "ddvalue": tblslot.data.coursedetails.s6_amount },
            { "ddname": "7", "ddvalue": tblslot.data.coursedetails.s9_amount },
            { "ddname": "8", "ddvalue": tblslot.data.coursedetails.s9_amount },
            { "ddname": "9", "ddvalue": tblslot.data.coursedetails.s9_amount },
            { "ddname": "10", "ddvalue": tblslot.data.coursedetails.s12_amount },
            { "ddname": "11", "ddvalue": tblslot.data.coursedetails.s12_amount },
            { "ddname": "12", "ddvalue": tblslot.data.coursedetails.s12_amount },
          ];
          updateState("price_list_id", ddprice1);
          let ddprice1p = [
            { "ddname": "1", "ddvalue": tblslot.data.coursedetails.p_amount },
            { "ddname": "2", "ddvalue": tblslot.data.coursedetails.g3_amount },
            { "ddname": "3", "ddvalue": tblslot.data.coursedetails.g3_amount },
            { "ddname": "4", "ddvalue": tblslot.data.coursedetails.g6_amount },
            { "ddname": "5", "ddvalue": tblslot.data.coursedetails.g6_amount },
            { "ddname": "6", "ddvalue": tblslot.data.coursedetails.g6_amount },
            { "ddname": "7", "ddvalue": tblslot.data.coursedetails.g9_amount },
            { "ddname": "8", "ddvalue": tblslot.data.coursedetails.g9_amount },
            { "ddname": "9", "ddvalue": tblslot.data.coursedetails.g9_amount },
            { "ddname": "10", "ddvalue": tblslot.data.coursedetails.g12_amount },
            { "ddname": "11", "ddvalue": tblslot.data.coursedetails.g12_amount },
            { "ddname": "12", "ddvalue": tblslot.data.coursedetails.g12_amount },
          ];
          updateState("price_list_id_p", ddprice1p);



          setPrice(true);
        } catch (err) {
        }
      };
      getData();
    }
  }, [tblslot, price]);

  const handleEnrollClick = (courseId, courseName, courseKeyPoints) => {
    const updatedapifieldsState = {
      course_id: courseId,
      course_name: courseName,
      course_key: courseKeyPoints,
      slot_id: "",
      slot_on: "",
      booking_type: "",
      amount: "",
      users: ""
    };
    updateState("course", updatedapifieldsState);
    setState((prevState) => ({
      ...prevState,
      final_amount: 0,
      discount_amount: 0,
      show_price: "N/A",
      applycoupon: false,
      checkbeforepayment: false,
      saved: false,
      is_edit: false,
      price_list: [],
      price_list_id: [],
      price_list_id_p: [],
      on_success: {
        course_name: "",
        student_name: "",
        amount: 0,
        is_success: false,
        order_id: "",
        payment_id: "",
        slot_on: "",
        date: "",
        payment_method: "",
        booking_count: ""
      },
      course_slot: {
        ...prevState.course_slot,
        course_id: "",
        course_name: "",
        course_key: "",
        slot_id: "",
        slot_on: "",
      },
      fields: {
        ...prevState.fields,
        selected_price: 0,
      }
    }));
    setSelectedSlotId(null);
    setShowBelowCode(true);
    setSelectionCode(true);
    setShowTransactionCheckout(false);
  };

  const handleCheckoutClick = (courseId, courseName, courseKeyPoints) => {
    // const updatedapifieldsState = {
    //   course_id: courseId,
    //   course_name: courseName,
    //   course_key: courseKeyPoints,
    //   slot_id: "",
    //   slot_on: "",
    //   booking_type: "",
    //   amount: "",
    //   users: ""
    // };
    // updateState("course", updatedapifieldsState);
    setCheckoutCode(true);
    setSelectionCode(false);
  };

  const handleCheckoutCancelIconClick = () => {
    setCheckoutCode(false);
    setSelectionCode(true);
  };

  const handleCouponCancelIconClick = () => {
    setState((prevState) => ({
      ...prevState,
      final_amount: 0,
      discount_amount: 0,
      applycoupon: false
    }));
  };


  const handleSlotClick = (slotId, slotOn) => {
    setState((prevState) => ({
      ...prevState,
      course_slot: {
        course_id: state.course.course_id,
        course_name: state.course.course_name,
        course_key: state.course.course_key,
        slot_id: slotId,
        slot_on: slotOn
      },
    }));
    setSelectedSlotId(slotId);
    // setShowUserCode(true);
  };


  const handleCancelIconClick = () => {
    setShowBelowCode(false);
    setSelectionCode(false);
    setShowTransactionCheckout(false);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab == "myCourses") {
      setMyCourse(true);
    }
    else if (tab == "lastTransactions") {
      setMyTrans(true);
    }
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("institute_id");
    localStorage.removeItem("user");
    navigate(`/`, {});
  };
  function formatDate(slotOn) {
    const slotDate = new Date(slotOn);

    // Define options for formatting the date
    const dateOptions = { weekday: 'short', month: 'short', day: 'numeric', timeZone: 'UTC' };

    // Format the date
    return slotDate.toLocaleDateString('en-US', dateOptions);
  }

  // function formatTime(slotOn) {
  //   const slotDate = new Date(slotOn);

  //   // Define options for formatting the time
  //   const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };

  //   // Format the time
  //   return slotDate.toLocaleTimeString('en-US', timeOptions);
  // }
  function formatTime(slotOn) {
    const slotDate = new Date(slotOn);

    // Define options for formatting the time
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'UTC' };

    // Format the time
    return slotDate.toLocaleTimeString('en-US', timeOptions);
  }


  // useEffect(() => {
  //   //console.log("state.user")
  //   //console.log(state.course_slot)
  //   //console.log(state.user)
  //   if (state.payclicked === true && 1 == 2) {
  //     const saveData = async () => {
  //       try {
  //         const newapiFields = { ...state.course_slot };
  //         const userinfo = { ...state.user };
  //         const result = await postAPI('bookingapi', newapiFields, true);
  //         if (result && result.statusCode === 201 && result.success === true) {
  //           showToast("Record has been added successfully.");
  //           clearData();
  //         } else {
  //           showToast("Something went wrong. Please try again later.");
  //         }

  //       } catch (err) {
  //       }
  //     };
  //     saveData();
  //     updateState("payclicked", false);
  //   }
  // }, [state.payclicked]);
  const payClicked = () => {
    const { selected_price } = state.fields;
    const { slot_id } = state.course_slot;
    let userinfo = [];
    handleCouponCancelIconClick();
    if (selected_price != "" && slot_id != "") {
      userinfo = collectUserData();
      if (userinfo === null) {
        showToast("Please enter firstname, lastname and phone number of attendee(s)");
        return;
      }
      setState((prevState) => ({
        ...prevState,
        payclicked: false
      }));

      setState((prevState) => ({
        ...prevState,
        payclicked: true,
        user: userinfo,
      }));

      setCheckoutCode(true);
      setSelectionCode(false);
    }
    else {
      showToast("Please select suitable slot and booking type");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedCheckoutClicked = debounce(payClicked, 500);

  useEffect(() => {
    if (state.applycoupon === true) {
      const saveData = async () => {
        try {
          let urldata = "?course_id=" + state.course_slot.course_id + "&coupon_code=" + state.fields.coupon_code;
          const result = await getAPI(`course/coupon` + urldata, false);
          if (result && result.statusCode === 200 && result.success === true) {
            //console.log(result);
            if (result.data.is_applied == true) {
              if (result.data.coupon_perc) {
                let discountAmount = ((parseInt(state.show_price) * state.user.length) * result.data.coupon_perc) / 100;
                const final_amount = Math.round((parseInt(state.show_price) * state.user.length) - discountAmount);
                discountAmount = (parseInt(state.show_price) * state.user.length) - final_amount;
                setState((prevState) => ({
                  ...prevState,
                  final_amount: final_amount,
                  discount_amount: discountAmount,
                  fields: {
                    ...prevState.fields,
                    coupon_code: "",
                  },
                }));
              }
            }
            showToast(result.data.coupon_message);
          } else {
            // showToast("Not applie");
          }
        } catch (err) {
        }
      };
      saveData();
      updateState("applycoupon", false);
    }
  }, [state.applycoupon]);
  const applyCoupon = () => {
    const { coupon_code } = state.fields;
    if (coupon_code != "" && coupon_code.length == 6) {
      setState((prevState) => ({
        ...prevState,
        applycoupon: false
      }));
      setState((prevState) => ({
        ...prevState,
        applycoupon: true
      }));
    }
  };
  const debouncedApplyclicked = debounce(applyCoupon, 500);

  const validateFields = () => {
    const { firstname, lastname, mobileno, email } = state.fields;
    if (!firstname || !lastname || !mobileno || !email) {
      // Check if required fields are empty
      alert("All fields are required");
      return false;
    }
    if (!/^\d{10}$/.test(mobileno)) {
      // Check if mobile number is valid
      alert("Mobile number must be 10 digits");
      return false;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      // Check if email is valid
      alert("Invalid email address");
      return false;
    }
    return true;
  };

  useEffect(() => {
    const validPrices = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    if (validPrices.includes(state.fields.selected_price)) {
      // if (state.fields.selected_price === "1" || state.fields.selected_price === "2" || state.fields.selected_price === "3" || state.fields.selected_price === "4" || state.fields.selected_price === "5" || state.fields.selected_price === "6") {
      const amount = getAmountForSelectedValue(state.fields.selected_price);
      updateState("show_price", amount);
    }
  }, [state.fields.selected_price, selectedOption]);


  const renderAdditionalFields = () => {
    const { selected_price } = state.fields;
    const validPrices = ["2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    if (validPrices.includes(state.fields.selected_price)) {

      //   if (selected_price === "3" || selected_price === "4" || selected_price === "5" || selected_price === "6") {
      let numUsers = 0;
      numUsers = selected_price;
      // if (selected_price === "3") {
      //   numUsers = 3;
      // }
      // else if (selected_price === "4") {
      //   numUsers = 6;
      // }
      // else if (selected_price === "5") {
      //   numUsers = 9;
      // }
      // else if (selected_price === "6") {
      //   numUsers = 12;
      // } else if (selected_price === "7") {
      //   numUsers = 3;
      // }
      // else if (selected_price === "8") {
      //   numUsers = 6;
      // }
      // else if (selected_price === "9") {
      //   numUsers = 9;
      // }
      // else if (selected_price === "10") {
      //   numUsers = 12;
      // } 
      const fields = [];
      for (let i = 0; i < numUsers; i++) {
        fields.push(
          <React.Fragment key={i}>
            <span className="txtspan">Attendee {i + 1} details</span>
            <input
              type="text"
              placeholder={`Firstname`}
              maxlength={50}
              className="form-control txtbox"
              value={state.fields[`firstname${i + 1}`]}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  fields: {
                    ...prevState.fields,
                    [`firstname${i + 1}`]: e.target.value,
                  },
                }))
              }
            />
            <input
              type="text"
              placeholder={`Lastname`}
              maxlength={50}
              className="form-control txtbox"
              value={state.fields[`lastname${i + 1}`]}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  fields: {
                    ...prevState.fields,
                    [`lastname${i + 1}`]: e.target.value,
                  },
                }))
              }
            />
            <input
              type="text"
              placeholder={`Mobileno`}
              className="form-control txtbox"
              value={state.fields[`mobileno${i + 1}`]}
              onChange={(e) => {
                let value = e.target.value;
                // Allow only digits for mobile number
                value = value.replace(/\D/g, '');
                // Limit to max 10 characters for mobile number
                if (value.length > 10) {
                  value = value.slice(0, 10);
                }
                setState((prevState) => ({
                  ...prevState,
                  fields: {
                    ...prevState.fields,
                    [`mobileno${i + 1}`]: value,
                  },
                }));
              }}
            />
            <input
              type="email"
              placeholder={`Email`}
              className="form-control txtbox"
              value={state.fields[`email${i + 1}`]}
              onChange={(e) => {
                let value = e.target.value;
                // Email validation
                const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                if (!isValidEmail) return; // Don't update state if email is invalid
                setState((prevState) => ({
                  ...prevState,
                  fields: {
                    ...prevState.fields,
                    [`email${i + 1}`]: value,
                  },
                }));
              }}
            />

          </React.Fragment>
        );
      }
      return <>{fields}</>;
    }
    return null;
  };

  const collectUserData = () => {
    const { selected_price } = state.fields;
    let userData = [];
    let attendeesRequired = 0;

    // Determine the number of attendees required based on the selected price
    // switch (selected_price) {
    //   case "3":
    //     attendeesRequired = 2;
    //     break;
    //   case "4":
    //     attendeesRequired = 4;
    //     break;
    //   case "5":
    //     attendeesRequired = 7;
    //     break;
    //   case "6":
    //     attendeesRequired = 10;
    //     break;
    //   case "7":
    //     attendeesRequired = 2;
    //     break;
    //   case "8":
    //     attendeesRequired = 4;
    //     break;
    //   case "9":
    //     attendeesRequired = 7;
    //     break;
    //   case "10":
    //     attendeesRequired = 10;
    //     break;
    //   default:
    //     attendeesRequired = 0;
    // }
    attendeesRequired = selected_price;
    if (selected_price == "1") {
      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        userData.push({
          first_name: getUser.first_name,
          last_name: getUser.last_name,
          mobile: getUser.phone,
          email: getUser.email,
        });
      }
    }
    else {
      for (let i = 0; i < (attendeesRequired); i++) {
        const firstName = state.fields[`firstname${i + 1}`];
        const lastName = state.fields[`lastname${i + 1}`];
        const mobile = state.fields[`mobileno${i + 1}`];
        const email = state.fields[`email${i + 1}`];
        // Validate data (you can add more specific validations here)
        if (!firstName || !lastName || !mobile || mobile.length != 10) {
          if ((i + 1) <= attendeesRequired) {
            return null;
          }
        }
        else {
          userData.push({
            first_name: firstName,
            last_name: lastName,
            mobile,
            email,
          });
        }
        // Push user data to the userData array

      }
    }
    // Return the array of user data if validation passes
    return userData;
  };
  const getAmountForSelectedValue = (selectedValue) => {
    let priceListId = [];
    if (selectedOption == "student") {
      priceListId = state.price_list_id;
    }
    else {
      priceListId = state.price_list_id_p;
    }
    //noofreg_list
    if (priceListId) {

      const selectedAmount = priceListId.find(item => item.ddname === selectedValue);
      return selectedAmount ? selectedAmount.ddvalue : 0; // Return 0 if no matching amount found
    }
    return 0; // Return default value if priceListId is not initialized
  };
  //#endregion 
  //#region ====================================================== Payment gateway =====================================================
  const generateRazorpayLink = async () => {
    try {
      const data = {
        amount: state.final_amount != 0 ? state.final_amount : (parseInt(state.show_price) * state.user.length),
        course_id: state.course_slot.course_id,
        slot_id: state.course_slot.slot_id,
        is_discount: state.discount_amount != 0 ? true : false,
        discount_amount: state.discount_amount != 0 ? state.discount_amount : 0,
        booking_count: state.user.length
      };
      const response = await postAPI("razorpayment/subscribe", data, false);
      return response;
    } catch (err) {
      console.error('Error:', err);
    }
  };
  const paymentVerification = async (payload, olddata) => {
    try {
      const requestData = { ...payload, user: olddata };
      const response = await postAPI("razorpayment/paymentVerification", requestData, false);
      //console.log("response");
      //console.log(response);
      if (response) {
        let getUser = localStorage.getItem("user");
        if (getUser) {
          getUser = JSON.parse(getUser);
          setShowTransactionCheckout(true);
          setSelectionCode(false);
          setCheckoutCode(false);
          setState((prevState) => ({
            ...prevState,
            on_success: {
              ...prevState.on_success,
              course_name: state.course_slot.course_name,
              student_name: getUser.first_name + " " + getUser.last_name,
              amount: response.data.amount,
              order_id: response.data.order_id,
              payment_id: response.data.payment_id,
              is_success: response.success,
              slot_on: state.course_slot.slot_on,
              date: response.data.created_at,
              payment_method: response.data.payment_method,
              booking_count: response.data.booking_count,
            },
          }));
          clearAllData();
        }
      }
      return response;
    } catch (err) {
      console.error('Error:', err);
    }
  };
  const clearAllData = () => {
    const updatedapifieldsState = {
      course_id: "",
      course_name: "",
      course_key: "",
      slot_id: "",
      slot_on: "",
      booking_type: "",
      amount: "",
      users: ""
    };
    updateState("course", updatedapifieldsState);
    setState((prevState) => ({
      ...prevState,
      final_amount: 0,
      discount_amount: 0,
      show_price: "N/A",
      applycoupon: false,
      checkbeforepayment: false,
      saved: false,
      is_edit: false,
      price_list: [],
      price_list_id: [],
      price_list_id_p: [],
      course_slot: {
        ...prevState.course_slot,
        course_id: "",
        course_name: "",
        course_key: "",
        slot_id: "",
        slot_on: "",
      },
      fields: {
        ...prevState.fields,
        selected_price: 0,
      }
    }));
    setSelectedSlotId(null);
  };
  useEffect(() => {
    if (state.checkbeforepayment === true) {
      const saveData = async () => {
        try {
          let urldata = "?course_id=" + state.course_slot.course_id + "&slot_id=" + state.course_slot.slot_id + "&regcount=" + state.user.length;
          const result = await getAPI(`course/slotcheck` + urldata, false);
          if (result && result.statusCode === 200 && result.success === true) {
            //console.log(result);
            if (result.data.can_payment == true) {
              handlePayment();
            }
            else {
              showToast(result.data.payment_message);
            }
          } else {
            showToast("Not available for booking. Please contact us directly");
            // showToast("No slots");
          }
        } catch (err) {
        }
      };
      saveData();
      updateState("checkbeforepayment", false);
    }
  }, [state.checkbeforepayment]);
  const checkBeforePayment = () => {
    setState((prevState) => ({
      ...prevState,
      checkbeforepayment: false
    }));
    setState((prevState) => ({
      ...prevState,
      checkbeforepayment: true
    }));
  };

  const debouncePayNow = debounce(checkBeforePayment, 500);

  const handlePayment = async () => {
    try {
      let getUser = localStorage.getItem("user");
      if (getUser) {
        getUser = JSON.parse(getUser);
        //slotcheck 
        // const result = await getAPI(`course/slotcheck?course_id=${state.course_slot.course_id}&slot_id=${state.course_slot.slot_id}`, false);
        // if (result && result.statusCode === 200 && result.success === true) {
        //   if (result.data.can_payment == true) {

        const data = await generateRazorpayLink();
        if (data && data.success === true) {
          const options = {
            key: "rzp_live_2o7FkoLr7vkK0A",
            amount: data.data.amount,
            currency: "INR",
            name: "Vasu TSDC",
            image: "https://vasutsdc.in/static/media/vasu.723180779ba8269344ce.png",
            order_id: data.data.order_id,
            handler: (response) => paymentVerification(response, state.user), // Call the function directly
            prefill: {
              name: getUser.first_name + " " + getUser.last_name,
              email: getUser.email,
              contact: getUser.phone,
            },
            theme: {
              color: "#121212",
            },
          };
          const razor = new window.Razorpay(options);
          razor.open();
        }

        // }
        // else {
        //   showToast(result.data.payment_message.toString());

        // }
        // } else {
        //   showToast("Slot not available while you were adding booking details");
        // }





      }
    } catch (err) {
      console.error('Error:', err);
      // setError('Login failed. Please check your credentials.');
    }
  };
  //#endregion
  //#region ====================================================== My courses =====================================================

  useEffect(() => {
    if (mycourse == true) {
      const getData = async () => {
        try {
          let getUser = localStorage.getItem("user");
          if (getUser) {
            getUser = JSON.parse(getUser);
            const result = await getAPI(`booking/course/${getUser.user_id}`, false);
            if (result && result.statusCode === 200 && result.success === true) {
              setTblMyCourse(result);
              handleMyCancelIconClick();
            } else {
              // showToast("Something went wrong. Please try again later.");
            }
          }
        } catch (err) {
        }

      };
      getData();
      setMyCourse(false);
    }
  }, [mycourse]);


  const handleRatingClick = (ratingValue) => {
    setState(prevState => ({
      ...prevState,
      rate_now: {
        ...prevState.rate_now,
        rating: ratingValue
      }
    }));
  };

  const reviewSubmitClicked = () => {
    const { review, rating } = state.rate_now;
    if (review !== undefined && rating !== undefined && review !== "" && rating !== 0) {
      const saveData = async () => {
        try {
          const newapiFields = { ...state.rate_now };
          const result = await postAPI('coursereview', newapiFields, true);
          if (result && result.statusCode === 201 && result.success === true) {
            setShowRateSection(false);
            setShowTransactionSection(false);
            showToast("Thank you for sharing your feedback! Your review has been successfully submitted.");
            handleTabClick('myCourses');
          } else {
            showToast(result.message);
          }
        } catch (err) {

        }
      };
      saveData();
    }
    else {
      showToast("Please select star rating and write your feedback before submitting.");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedRateSubmitClicked = debounce(reviewSubmitClicked, 500);


  const reviewReSubmitClicked = () => {
    const { review, rating } = state.rate_now;
    if (review !== undefined && rating !== undefined && review !== "" && rating !== 0) {
      const saveData = async () => {
        try {
          const newapiFields = { ...state.rate_now };
          const result = await putAllAPI('coursereview', newapiFields, true);
          if (result && result.statusCode === 200 && result.success === true) {
            setShowRateSection(false);
            setShowTransactionSection(false);
            showToast("Your review has been updating successfully submitted.");
            handleTabClick('myCourses');
          } else {
            showToast(result.message);
          }
        } catch (err) {

        }
      };
      saveData();
    }
    else {
      showToast("Please select star rating and write your feedback before submitting.");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  const debouncedRateReSubmitClicked = debounce(reviewReSubmitClicked, 500);

  const getTransaction = (responseId) => {
    //console.log("responseId");
    //console.log(responseId);
    if (responseId !== undefined && responseId != 0) {
      const saveData = async () => {
        try {
          const newapiFields = {};
          const result = await getAPI(`booking/transaction/${responseId}`, false);
          if (result && result.statusCode === 200 && result.success === true) {
            setShowTransactionSection(true);
            setShowRateSection(false);
            setState((prevState) => ({
              ...prevState,
              transuser: result.data.transdetail.user,
              mycourse_trans: result.data.transdetail,
            }));
          } else {
            showToast(result.message);
          }
        } catch (err) {

        }
      };
      saveData();
    } else {
      showToast("Please try again later.");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  //#endregion



  //#region ====================================================== My Transactions =====================================================

  useEffect(() => {
    if (mytrans == true) {
      const getData = async () => {
        try {
          let getUser = localStorage.getItem("user");
          if (getUser) {
            getUser = JSON.parse(getUser);
            const result = await getAPI(`booking/transactionlist/${getUser.user_id}`, false);
            if (result && result.statusCode === 200 && result.success === true) {
              setTblMyTrans(result);
              handleMyTransCancelClick();
            } else {
              // showToast("Something went wrong. Please try again later.");
            }
          }
        } catch (err) {
        }

      };
      getData();
      setMyTrans(false);
    }
  }, [mytrans]);

  const handleMyTransCancelClick = () => {
    setShowTransactionDetails(false);
    setState((prevState) => ({
      ...prevState,
      transuser: [],
      mycourse_trans: {
        course_name: "",
        student_name: "",
        amount: 0,
        is_success: false,
        order_id: "",
        payment_id: "",
        slot_on: "",
        date: "",
        payment_method: "",
        booking_count: ""
      }
    }));

  };

  const getmyTransaction = (responseId) => {
    if (responseId !== undefined && responseId != 0) {
      const saveData = async () => {
        try {
          const newapiFields = {};
          const result = await getAPI(`booking/transaction/${responseId}`, false);
          if (result && result.statusCode === 200 && result.success === true) {
            setShowTransactionDetails(true);
            setState((prevState) => ({
              ...prevState,
              transuser: result.data.transdetail.user,
              mycourse_trans: result.data.transdetail,
            }));
          }
          else {
            showToast(result.message);
          }
        } catch (err) {
        }
      };
      saveData();
    } else {
      showToast("Please try again later.");
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  //#endregion

  return (
    <>
      <div className="flex min-h-screen w-full flex-col justify-between">
        <div className="flex w-full flex-col justify-center px-5">
          <div className="mx-auto w-full max-w-md md:max-w-lg lg:max-w-xl 2xl:pb-8 2xl:pt-2 paddingtop1">
            <div className="flex flex-col items-center">
              <div className="maindiv">
                <a className="displayinlineblock">
                  <img alt="vasu" fetchPriority="high" width="155" height="28" decoding="async" data-nimg="1" className="dark:invert logocss" src={logoimage} />
                </a>
                <div className="right-float">
                  <a onClick={handleLogout} className="nk-menu-link nk-menu-link1 bottomname nk-menu-link-custom pointercursor">
                    <div className="media media-md media-circle media-margin-right">
                      <img src={userimage} alt="" className="img-thumbnail" />
                    </div>
                    <span className="bottomtitle">
                      <span className="nk-menu-text">{state.user_name}</span>
                      <span className="nk-menu-text font-size12 underline-text">Click to logout</span>
                    </span>
                  </a>
                </div>
              </div>

              <div className="custom-wrapper">
                <div className="custom-border-1">
                  <div className={activeTab === 'joinCourse' ? 'custom-button-1 active' : 'custom-button-2'} onClick={() => handleTabClick('joinCourse')}>
                    <span className="custom-text-1">Join course</span>
                  </div>
                  <div className={activeTab === 'myCourses' ? 'custom-button-1 active' : 'custom-button-2'} onClick={() => handleTabClick('myCourses')}>
                    <span className="custom-text-2">My courses</span>
                  </div>
                  <div className={activeTab === 'lastTransactions' ? 'custom-button-1 active' : 'custom-button-2'} onClick={() => handleTabClick('lastTransactions')}>
                    <span className="custom-text-3">Last transactions</span>
                  </div>
                </div>
              </div>

              <div className="custom-wrapper">
                <div className="custom-content">
                  {/* Content for each tab */}
                  {activeTab === 'joinCourse' &&
                    <div>

                      {!showBelowCode && (
                        <>
                          <div className="custom-title">
                            <span>Select course you want to join</span>
                          </div>
                          {tbldata &&
                            (!tbldata.data || !tbldata.data.courses?.length) ? (
                            <>
                              <div className="norecordcss">
                                <img
                                  src={emptyrecord}
                                  width="330px"
                                  alt="No record found"
                                />
                                <div className="norecordfont">No record found</div>
                              </div>
                            </>
                          ) : (
                            <>
                              {tbldata &&
                                tbldata.data.courses.map((x) => (
                                  <>
                                    <div key={x.course_id} onClick={() => handleEnrollClick(x.course_id, x.course_name, x.key_points)} className="custom-course">
                                      <div className="custom-image">
                                        <div className="img-thumbnail imgtags"  >
                                        </div>
                                      </div>
                                      <div className="custom-details">
                                        <span className="custom-course-title">{x.course_name}</span>
                                        <span className="custom-course-description">{x.description}</span>
                                        <div className="custom-buttons">
                                          <span className="custom-tag">{x.course_type.course_type_name}</span>
                                          <span className="custom-enroll">Enroll Now</span>

                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ))}
                            </>
                          )}
                        </>
                      )}
                      {showBelowCode && (
                        <>
                          {selectionCode && (
                            <>
                              {tblslot &&
                                (!tblslot.data || !tblslot.data.courseslots?.length) ? (
                                <>
                                  <div>
                                    <div className="custom-title width100">
                                      <span className="maintitle">All slots are booked, but don't worry! Explore our other courses for available options.</span>
                                      <span className="cancleicon" onClick={handleCancelIconClick}><em className="icon ni ni-cross canceliconin"></em></span>
                                    </div>
                                    <div className="norecordcss">
                                      <img
                                        src={emptyrecord}
                                        width="330px"
                                        alt="No record found"
                                      />
                                      {/* <div className="norecordfont">No record found</div> */}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div>
                                    <div className="custom-title width100">
                                      <span className="maintitle">Please select available slot that suits you</span>
                                      <span className="cancleicon" onClick={handleCancelIconClick}><em className="icon ni ni-cross canceliconin"></em></span>
                                    </div>
                                    <span className="custom-course-title startitle ">{state.course.course_name}</span>
                                    <div className="startop ">
                                      {tblslot &&
                                        tblslot.data.courseslots.map((x) => {
                                          const isStatusTrue = x.is_status;
                                          const isMaxCountAlmostFull = x.max_count - x.registered_count <= 20;
                                          const isMaxCountCloseToFull = x.max_count - x.registered_count <= 5;
                                          // Determine the className based on conditions
                                          let classNames = "custom-tag custome-tagpad";

                                          if (isMaxCountAlmostFull) {
                                            classNames += " slotgreencolor";
                                          }
                                          if (isMaxCountCloseToFull) {
                                            classNames += " slotredcolor";
                                          }
                                          if (isStatusTrue) {
                                            classNames += " slotredcolor";
                                          }
                                          if (selectedSlotId == x.slot_id) {
                                            classNames += " slotselected";
                                          }



                                          return (
                                            <span
                                              key={x.slot_id}
                                              onClick={() => handleSlotClick(x.slot_id, x.slot_on)}
                                              className={classNames}
                                            >
                                              <span className="slotname ">{formatDate(x.slot_on)}</span><span className="slotname ">{formatTime(x.slot_on)}</span>

                                            </span>
                                          );
                                        })
                                      }

                                    </div >
                                    <div className="startopdescription ">
                                      <span className="displayblock">{state.course.course_key}</span>
                                    </div>
                                    <div className="startopdescription confirmbook">
                                      <div className="row">
                                        <div className="col-lg-12">
                                          <div className="form-group">

                                            <label htmlFor="tax-class" className="form-label">Please select booking type</label>
                                            <br />
                                            <div className="radiodiv">
                                              <label>

                                                <input
                                                  type="radio"
                                                  value="student"
                                                  checked={selectedOption === 'student'}
                                                  onChange={handleOptionChange}
                                                />
                                                <span className="spanradio">Book as student</span>

                                              </label>
                                              <label className="spanradioleft">

                                                <input
                                                  type="radio"
                                                  value="professional"
                                                  checked={selectedOption === 'professional'}
                                                  onChange={handleOptionChange}
                                                />
                                                <span className="spanradio">Book as professional</span>

                                              </label>
                                            </div>
                                            <div className="form-control-wrap">
                                              <Select2
                                                data={[
                                                  ...(state.noofreg_list || []).map((x) => ({
                                                    text: x.ddname,
                                                    id: x.ddvalue,
                                                    key: x.ddname.toString(),
                                                  })),
                                                ]}
                                                options={{
                                                  placeholder: "How many attendees?",
                                                }}
                                                name="selected_price"
                                                className="form-control"
                                                value={state.fields.selected_price}
                                                onChange={handleInputChange}
                                              />
                                            </div>

                                          </div>
                                        </div>
                                        <div className="col-lg-12">

                                          {renderAdditionalFields()}
                                          *Take advantage of our group booking discount for multiple attendees.
                                          {/* {state.fields.selected_price && (
                                            <span className="txtspan1">
                                              *You need to enter at least {state.fields.selected_price} attendees details
                                               {state.fields.selected_price === "4" && "*You need to write at least 4 attendees details"}
                                              {state.fields.selected_price === "5" && "*You need to write at least 7 attendees details"}
                                              {state.fields.selected_price === "6" && "*You need to write at least 10 attendees details"}  
                                            </span>
                                          )} */}

                                        </div>
                                        <div className="col-lg-12">
                                          <div class="feesinfo">
                                            <div>
                                              {state.fields.selected_price && (
                                                <>
                                                  <span class="tamount1">
                                                    <div>
                                                      <em className="icon ni ni-sign-inr"></em>
                                                      {state.show_price}
                                                    </div>
                                                  </span>
                                                  <span class="custom-course-title">Fees per Attendee</span>
                                                  < span>  <a href="https://vasutsdc.in" target="_blank" className="visitwebsite">Visit Website For Course Details</a></span>
                                                </>
                                              )}
                                            </div>
                                            <div>
                                              <button type="button" className="custom-enroll" onClick={debouncedCheckoutClicked}>Checkout</button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="divbottom positionrelative"><div className="bottombutton bottombtn"><ul className="d-flex align-items-center gap g-3"><li><span className="bird-total "><span className="slotgreen "></span><span className="bird-text">Filling Faster</span></span></li><li><span className="bird-total "><span className="slotred"></span><span className="bird-text">Limited Spots Remaining</span></span></li></ul></div></div>
                                  </div>
                                </>
                              )}

                            </>
                          )}
                          {checkoutCode && (
                            <>
                              {tblslot &&
                                (!tblslot.data || !tblslot.data.courseslots?.length) ? (
                                <>
                                  <div>

                                    <div className="custom-title width100">
                                      <span className="maintitle">All slots are booked, but don't worry! Explore our other courses for available options.</span>
                                      <span className="cancleicon" onClick={handleCheckoutCancelIconClick}><em className="icon ni ni-cross canceliconin"></em></span>
                                    </div>
                                    <div className="norecordcss">
                                      <img
                                        src={emptyrecord}
                                        width="330px"
                                        alt="No record found"
                                      />
                                      {/* <div className="norecordfont">No record found</div> */}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div>

                                    {/* handleCheckoutClick */}
                                    <div className="custom-title width100">
                                      <span className="maintitle">Review your selected course and time slot before proceeding to payment.</span>
                                      <span className="cancleicon" onClick={handleCheckoutCancelIconClick}><em className="icon ni ni-cross canceliconin"></em></span>
                                    </div>
                                    <span className="custom-course-title startitle ">{state.course_slot.course_name}</span>
                                    <span className="custom-course-title startitle ">{formatDate(state.course_slot.slot_on)} {formatTime(state.course_slot.slot_on)}   </span>



                                    <div className="startop ">
                                      <span className="startitle margin10bottom">Attendee(s) details</span>
                                      {state.user.map((x) => {
                                        return (
                                          <>
                                            <div key={x.mobile} class="userinfo">
                                              <div class="custom-image">
                                                <img src={userimage} alt="" class="img-thumbnail userphoto" />
                                              </div>
                                              <div class="custom-details">
                                                <span class="custom-course-title">{x.first_name} {x.last_name}</span>
                                                <span class="custom-course-description">{x.mobile}</span>
                                                <span class="custom-course-description">{x.email}</span>
                                              </div>
                                            </div>
                                          </>
                                        );
                                      })}
                                      <div class="feesinfo">
                                        {state.final_amount !== 0 ? (
                                          <div class="custom-course marginbottom0">
                                            <span class="scirclegreen ">
                                              <em class="icon ni ni-check scircleicon"></em>
                                            </span>
                                            <div class="custom-details">
                                              <span class="custom-course-title">Coupon {state.fields.coupon_code} applied successfully.</span>
                                              <span class="custom-course-description">Congratulations!, Enjoy your savings of <span class="inrgreen1"> <em class="icon ni ni-sign-inr"></em>{state.discount_amount}</span>
                                              </span>
                                            </div>
                                            <div>
                                              <span className="cancleicon" onClick={handleCouponCancelIconClick}><em className="icon ni ni-cross canceliconin"></em></span>

                                            </div>
                                          </div>
                                        ) : (
                                          <>


                                            <input
                                              type="text"
                                              name="coupon_code"
                                              maxLength={6}
                                              placeholder={`Enter coupon code if you have any`}
                                              className="form-control"
                                              value={state.fields.coupon_code}
                                              onChange={handleInputChange}
                                            />


                                            <div>
                                              <button type="button" className="custom-enroll marginleft10" onClick={debouncedApplyclicked}>Apply</button>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                      <div class="feesinfo">
                                        <div>
                                          <span class="tamount1">
                                            {state.final_amount !== 0 ? (
                                              <div>
                                                <em className="icon ni ni-sign-inr"></em>
                                                {state.final_amount}
                                                <del className="left15">
                                                  <em className="icon ni ni-sign-inr"></em>
                                                  {parseInt(state.show_price) * state.user.length}
                                                </del>
                                              </div>
                                            ) : (
                                              <div>
                                                <em className="icon ni ni-sign-inr"></em>
                                                {parseInt(state.show_price) * state.user.length}
                                              </div>
                                            )}
                                          </span>
                                          <span class="custom-course-title">Total Payable</span>
                                          <span class="custom-course-description1">Fees <em class="icon ni ni-sign-inr"></em>{state.show_price} x {state.user.length}  attendee</span>

                                        </div>
                                        <div>
                                          <button type="button" className="custom-enroll" onClick={debouncePayNow}>Pay Now</button>
                                        </div>
                                      </div>
                                    </div >
                                  </div>
                                </>
                              )}




                            </>


                          )}
                          {showTransactionCheckout && (
                            <div>
                              <div className="custom-title width100">
                                <span className="maintitle">Transaction details</span>
                                <span className="cancleicon " onClick={handleTransCancelIconClick}><em className="icon ni ni-cross canceliconin"></em></span>
                              </div>
                              <div className="custom-course transmain
">
                                <div className="custom-details">
                                  <div className="sround ">
                                    <span className={state.on_success.is_success == true ? "scirclegreen" : "scirclered"}>
                                      {state.on_success.is_success == true ? <em className="icon ni ni-check scircleicon"></em> : <em className="icon ni ni-cross scircleicon"></em>}
                                    </span>
                                  </div>

                                  <div className="tmain">
                                    <span className="tamount"><em className="icon ni ni-sign-inr"></em>{state.on_success.amount}</span>
                                    <span className="tsuccess">{state.on_success.is_success == true ? "Paid successfully" : "Transaction failed"} </span>
                                  </div>
                                  <div className="custom-buttons transitem">
                                    <span className="custom-tag transleft">Order ID</span>
                                    <span className="custom-enroll transright">{state.on_success.order_id}</span>
                                  </div>
                                  <div className="custom-buttons transitem">
                                    <span className="custom-tag transleft">Transaction ID</span>
                                    <span className="custom-enroll transright">{state.on_success.payment_id}</span>
                                  </div>
                                  <div className="custom-buttons transitem">
                                    <span className="custom-tag transleft">Student Name</span>
                                    <span className="custom-enroll transright">{state.on_success.student_name}</span>
                                  </div>
                                  <div className="custom-buttons transitem">
                                    <span className="custom-tag transleft">Course</span>
                                    <span className="custom-enroll transright">{state.on_success.course_name}</span>
                                  </div>
                                  <div className="custom-buttons transitem">
                                    <span className="custom-tag transleft">Attendees</span>
                                    <span className="custom-enroll transright">{state.on_success.booking_count}</span>
                                  </div>
                                  <div className="custom-buttons transitem">
                                    <span className="custom-tag transleft">Slot</span>
                                    <span className="custom-enroll transright">{formatDate(state.on_success.slot_on)} {formatTime(state.on_success.slot_on)} </span>
                                  </div>
                                  <div className="custom-buttons transitem">
                                    <span className="custom-tag transleft">Date</span>
                                    <span className="custom-enroll transright">{state.on_success.date}</span>
                                  </div>
                                  <div className="custom-buttons transitem">
                                    <span className="custom-tag transleft">Payment Method</span>
                                    <span className="custom-enroll transright">{state.on_success.payment_method}</span>
                                  </div>

                                </div>
                              </div>
                            </div>

                          )}

                        </>
                      )}


                    </div>
                  }
                  {activeTab === 'myCourses' &&
                    <div>
                      {!showRateSection && !showTransactionSection && (
                        <div>
                          <div className="custom-title">
                            <span>Recently joined courses</span>
                          </div>
                          {tblmycourse ? (
                            tblmycourse.data && tblmycourse.data.courses?.length ? (
                              <>
                                {tblmycourse &&
                                  tblmycourse.data.courses.map((x) => (
                                    <>
                                      {/* */}
                                      <div key={x.booking_id} className="custom-course custom-coursenopoint">
                                        <div className="custom-image">
                                          <div className="img-thumbnail imgtags"  >
                                          </div>
                                        </div>
                                        <div className="custom-details">
                                          <span className="custom-course-title">{x.course?.course_name}</span>
                                          <span className="custom-course-description">{x.course?.description}</span>
                                          <div className="custom-buttons custom-tag-cursor">
                                            <span className="custom-tag custom-tag-cursor">{formatDate(x.slot?.slot_on)} {formatTime(x.slot?.slot_on)}</span>
                                            <span className="custom-enroll" onClick={() => getTransaction(x.response_id)} >View Transaction</span>
                                            <span className="custom-enroll" onClick={() => handleRateNowClick(x.course?.course_id, x.course?.course_name, x.booking_id, x.course_reviews[0]?.review_id, x.slot_id, x.course_reviews[0]?.rating, x.course_reviews[0]?.review)}>
                                              {x.course_reviews.length != 0 ? <>
                                                <em className="icon ni ni-star scircleicon starticonline"></em> {x.course_reviews[0]?.rating}
                                              </>
                                                : (
                                                  "Rate Now"
                                                )}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ))}


                              </>
                            ) : (
                              <>
                                <div className="norecordcss">
                                  <img
                                    src={emptyrecord}
                                    width="330px"
                                    alt="No record found"
                                  />
                                  <div className="norecordfont">No record found</div>
                                </div>
                              </>
                            )
                          ) : (
                            <>
                              <div className="norecordcss">
                                <img
                                  src={emptyrecord}
                                  width="330px"
                                  alt="No record found"
                                />
                                <div className="norecordfont">No record found</div>
                              </div>
                            </>
                          )}
                        </div>
                      )}

                      {showTransactionSection && (
                        <div>
                          <div className="custom-title width100">
                            <span className="maintitle">Transaction details</span>
                            <span className="cancleicon " onClick={handleMyCancelIconClick}><em className="icon ni ni-cross canceliconin"></em></span>
                          </div>
                          <div className="custom-course transmain
">
                            <div className="custom-details">
                              <div className="sround ">
                                <span className={state.mycourse_trans.is_success == true ? "scirclegreen" : "scirclered"}>
                                  {state.mycourse_trans.is_success == true ? <em className="icon ni ni-check scircleicon"></em> : <em className="icon ni ni-cross scircleicon"></em>}
                                </span>
                              </div>

                              <div className="tmain">
                                <span className="tamount"><em className="icon ni ni-sign-inr"></em>{state.mycourse_trans.amount}</span>
                                <span className="tsuccess">{state.mycourse_trans.is_success == true ? "Paid successfully" : "Transaction failed"} </span>
                              </div>
                              <div className="custom-buttons transitem">
                                <span className="custom-tag transleft">Order ID</span>
                                <span className="custom-enroll transright">{state.mycourse_trans.order_id}</span>
                              </div>
                              <div className="custom-buttons transitem">
                                <span className="custom-tag transleft">Transaction ID</span>
                                <span className="custom-enroll transright">{state.mycourse_trans.payment_id}</span>
                              </div>
                              <div className="custom-buttons transitem">
                                <span className="custom-tag transleft">Course</span>
                                <span className="custom-enroll transright">{state.mycourse_trans.course_name}</span>
                              </div>
                              <div className="custom-buttons transitem">
                                <span className="custom-tag transleft">Attendees</span>
                                <span className="custom-enroll transright">{state.mycourse_trans.booking_count}</span>
                              </div>
                              <div className="custom-buttons transitem">
                                <span className="custom-tag transleft">Slot</span>
                                <span className="custom-enroll transright">{formatDate(state.mycourse_trans.slot_on)} {formatTime(state.mycourse_trans.slot_on)} </span>
                              </div>
                              <div className="custom-buttons transitem">
                                <span className="custom-tag transleft">Date</span>
                                <span className="custom-enroll transright">{state.mycourse_trans.date}</span>
                              </div>
                              <div className="custom-buttons transitem">
                                <span className="custom-tag transleft">Payment Method</span>
                                <span className="custom-enroll transright">{state.mycourse_trans.payment_method}</span>
                              </div>

                            </div>
                          </div>
                          <div className="startop ">
                            <span className="startitle margin10bottom">Attendee(s) details</span>
                            {state.transuser.map((x) => {
                              return (
                                <>
                                  <div key={x.mobile} class="userinfo">
                                    <div class="custom-image">
                                      <img src={userimage} alt="" class="img-thumbnail userphoto" />
                                    </div>
                                    <div class="custom-details">
                                      <span class="custom-course-title">{x.first_name} {x.last_name}</span>
                                      <span class="custom-course-description">{x.phone}</span>
                                      <span class="custom-course-description">{x.email}</span>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      )}

                      {showRateSection && (
                        <div>
                          <div className="custom-title width100">
                            <span className="maintitle">Please rate your experience</span>
                            <span className="cancleicon " onClick={handleMyCancelIconClick}><em className="icon ni ni-cross canceliconin"></em></span>
                          </div>
                          <span className="custom-course-title startitle ">{state.rate_now.course_name}</span>
                          <div className="startop ">
                            {[1, 2, 3, 4, 5].map(starValue => (
                              <em
                                key={starValue}
                                className={`icon ni ni-star staricon ${starValue <= state.rate_now.rating ? 'selectedstarcolor' : 'starblank'}`}
                                onClick={() => handleRatingClick(starValue)}
                              ></em>
                            ))}
                          </div>
                          <div className="startopdescription ">
                            <div className="form-control-wrap">
                              <textarea
                                type="text"
                                placeholder="Write your review"
                                name="review"
                                maxLength={500}
                                rows="4"
                                className="form-control py-2 h-14 x-5"
                                value={state.rate_now.review}
                                onChange={handleTextareaChange}
                              />
                            </div>
                          </div>
                          <div className="startopdescription">
                            {state.rate_now.review_id != null ? <>
                              <span className="custom-enroll" onClick={debouncedRateReSubmitClicked}>Update review</span>
                            </>
                              : <>
                                <span className="custom-enroll" onClick={debouncedRateSubmitClicked}>Submit your review</span></>
                            }
                          </div>
                        </div>
                      )}

                    </div>
                  }
                  {activeTab === 'lastTransactions' &&
                    <div>
                      {!showTransactionDetails && (
                        <>
                          <div className="custom-title">
                            <span>Your recent transactions</span>
                          </div>
                          {tblmytrans ? (
                            tblmytrans.data && tblmytrans.data.transdetail?.length ? (
                              <>
                                {tblmytrans &&
                                  tblmytrans.data.transdetail.map((x) => (
                                    <>
                                      <div key={x.booking_id} onClick={() => getmyTransaction(x.response_id)} className="custom-course">
                                        <div className="custom-image">
                                          <em className="ni ni-sign-inr-alt fontsize42"></em>
                                        </div>
                                        <div className="custom-details">
                                          <span className="custom-course-title">{x.course?.course_name}</span>
                                          <span className="custom-course-description">
                                            {formatDate(x.createdAt)}  {formatTime(x.createdAt)}     <span className={x.captured == true ? "inrgreen" : "inrred"} > <em className="icon ni ni-sign-inr"></em>{x.amount}</span>
                                          </span>
                                        </div>
                                      </div >
                                    </>
                                  ))}
                              </>
                            ) : (
                              <>
                                <div className="norecordcss">
                                  <img
                                    src={emptyrecord}
                                    width="330px"
                                    alt="No record found"
                                  />
                                  <div className="norecordfont">No record found</div>
                                </div>
                              </>
                            )
                          ) : (
                            <>
                              <div className="norecordcss">
                                <img
                                  src={emptyrecord}
                                  width="330px"
                                  alt="No record found"
                                />
                                <div className="norecordfont">No record found</div>
                              </div>
                            </>
                          )}
                        </>
                      )}

                      {showTransactionDetails && (
                        <div>
                          <div className="custom-title width100">
                            <span className="maintitle">Transaction details</span>
                            <span className="cancleicon " onClick={handleMyTransCancelClick}><em className="icon ni ni-cross canceliconin"></em></span>
                          </div>
                          <div className="custom-course transmain
   ">
                            <div className="custom-details">
                              <div className="sround ">
                                <span className={state.mycourse_trans.is_success == true ? "scirclegreen" : "scirclered"}>
                                  {state.mycourse_trans.is_success == true ? <em className="icon ni ni-check scircleicon"></em> : <em className="icon ni ni-cross scircleicon"></em>}
                                </span>
                              </div>

                              <div className="tmain">
                                <span className="tamount"><em className="icon ni ni-sign-inr"></em>{state.mycourse_trans.amount}</span>
                                <span className="tsuccess">{state.mycourse_trans.is_success == true ? "Paid successfully" : "Transaction failed"} </span>
                              </div>
                              <div className="custom-buttons transitem">
                                <span className="custom-tag transleft">Order ID</span>
                                <span className="custom-enroll transright">{state.mycourse_trans.order_id}</span>
                              </div>
                              <div className="custom-buttons transitem">
                                <span className="custom-tag transleft">Transaction ID</span>
                                <span className="custom-enroll transright">{state.mycourse_trans.payment_id}</span>
                              </div>
                              <div className="custom-buttons transitem">
                                <span className="custom-tag transleft">Course</span>
                                <span className="custom-enroll transright">{state.mycourse_trans.course_name}</span>
                              </div>
                              <div className="custom-buttons transitem">
                                <span className="custom-tag transleft">Attendees</span>
                                <span className="custom-enroll transright">{state.mycourse_trans.booking_count}</span>
                              </div>
                              <div className="custom-buttons transitem">
                                <span className="custom-tag transleft">Slot</span>
                                <span className="custom-enroll transright">{formatDate(state.mycourse_trans.slot_on)} {formatTime(state.mycourse_trans.slot_on)} </span>
                              </div>
                              <div className="custom-buttons transitem">
                                <span className="custom-tag transleft">Date</span>
                                <span className="custom-enroll transright">{state.mycourse_trans.date}</span>
                              </div>
                              <div className="custom-buttons transitem">
                                <span className="custom-tag transleft">Payment Method</span>
                                <span className="custom-enroll transright">{state.mycourse_trans.payment_method}</span>
                              </div>

                            </div>
                          </div>
                          <div className="startop ">
                            <span className="startitle margin10bottom">Attendee(s) details</span>
                            {state.transuser.map((x) => {
                              return (
                                <>
                                  <div key={x.mobile} class="userinfo">
                                    <div class="custom-image">
                                      <img src={userimage} alt="" class="img-thumbnail userphoto" />
                                    </div>
                                    <div class="custom-details">
                                      <span class="custom-course-title">{x.first_name} {x.last_name}</span>
                                      <span class="custom-course-description">{x.phone}</span>
                                      <span class="custom-course-description">{x.email}</span>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </div>


                      )}


                    </div>
                  }
                </div>
              </div>





            </div>

          </div>
        </div>

      </div >
      <div className="bottomfix ">
        © 2024 Vasu Training Center. All rights reserved.
      </div>
      {/* ====================================================== Toast message ====================================================== */}
      {
        state.show_toast && (
          <div
            className="toast align-items-center text-bg-primary border-0 fade show toastmsg"
            role="alert"
            aria-live="assertive"
            aria-atomic="true"
          >
            <div className="d-flex">
              <div className="toast-body">{state.toast_msg}</div>
              <button
                type="button"
                className="btn-close btn-close-white me-2 m-auto"
                data-bs-dismiss="toast"
                onClick={(e) => hideToast()}
                aria-label="Close"
              ></button>
            </div>
          </div>
        )
      }


    </>
  );
};

export default authLayout(BookSlot);
